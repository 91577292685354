import * as React from "react"
import masterLogoWhite from "@/assets/logo/logo.png";

import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/ui/sidebar"

export function MySidebarHeader() {
  const [selectedLogo] = React.useState<string>(masterLogoWhite);
  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <SidebarMenuButton
          size="lg"
          className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
        >
          <div className="flex aspect-square min-w-[40px] items-center justify-center rounded-lg text-sidebar-primary-foreground">
            <img
              width={100}
              height={70}
              src={selectedLogo}
              alt="scrap-logo"
            />
          </div>
          <div className="grid flex-1 text-left text-sm leading-tight">
            <div className="flex items-center justify-center">
              <h1 className="text-2xl font-bold tracking-wider">
                <span className="text-destructive">S</span>
                crap
              </h1>
            </div>
          </div>
        </SidebarMenuButton>
      </SidebarMenuItem>
    </SidebarMenu>
  )
}
