import { ChartConfig } from "@/components/ui/chart";

// Globals
export const xyChartConfig = {
    x: "x",
    y: "y",
    y2: "y2",
    yColor: "var(--color-y)",
    y2Color: "var(--color-y2)",
}
export const chartConfig = {
    visitors: {
        label: "Visitors",
    },
    chrome: {
        label: "Chrome",
        color: "hsl(var(--chart-1))",
    },
    safari: {
        label: "Safari",
        color: "hsl(var(--chart-2))",
    },
    firefox: {
        label: "Firefox",
        color: "hsl(var(--chart-3))",
    },
    edge: {
        label: "Edge",
        color: "hsl(var(--chart-4))",
    },
    other: {
        label: "Other",
        color: "hsl(var(--chart-5))",
    },
    y: {
        label: "Desktop",
        color: "hsl(var(--chart-1))",
    },
    y2: {
        label: "Mobile",
        color: "hsl(var(--chart-2))",
    },
} satisfies ChartConfig

// Data
export type xyChartEntry = {
    x: string;
    y: number;
    y2?: number;
}
export const lineChartData: xyChartEntry[] = [
    { x: "January", y: 186, y2: 80 },
    { x: "February", y: 305, y2: 200 },
    { x: "March", y: 237, y2: 120 },
    { x: "April", y: 73, y2: 190 },
    { x: "May", y: 209, y2: 130 },
    { x: "June", y: 214, y2: 140 },
]
export const chartData = [
    { browser: "chrome", visitors: 275, fill: "var(--color-chrome)" },
    { browser: "safari", visitors: 200, fill: "var(--color-safari)" },
    { browser: "firefox", visitors: 187, fill: "var(--color-firefox)" },
    { browser: "edge", visitors: 173, fill: "var(--color-edge)" },
    { browser: "other", visitors: 90, fill: "var(--color-other)" },
]


export type IListEntry = {
    value: number; // The monetary amount as a number (e.g., 1999.00)
    valueHead?: string; // The prefix (e.g., "+", "-")
    valueTail?: string; // The suffix (e.g., "BH", "USD")
    label: string; // Name of the individual
    subtitle?: string; // Email or additional info
    image?: string; // Avatar image URL
    imageFallback?: string; // Fallback initials for the avatar
};
export type IList = {
    title: string;
    description?: string;
    data: IListEntry[]
    valueHead?: string,
    valueTail?: string,
}
export type IBarChart = IList
export const salesValueHead = "+";
export const salesValueTail = "BH";
export const sales: IListEntry[] = [
    {
        value: 1999.0,
        valueHead: salesValueHead,
        valueTail: salesValueTail,
        label: "Olivia Martin",
        subtitle: "olivia.martin@email.com",
        image: "/avatars/01.png",
        imageFallback: "OM",
    },
    {
        value: 39.0,
        valueHead: salesValueHead,
        valueTail: salesValueTail,
        label: "Jackson Lee",
        subtitle: "jackson.lee@email.com",
        image: "/avatars/02.png",
        imageFallback: "JL",
    },
    {
        value: 299.0,
        valueHead: salesValueHead,
        valueTail: salesValueTail,
        label: "Isabella Nguyen",
        subtitle: "isabella.nguyen@email.com",
        image: "/avatars/03.png",
        imageFallback: "IN",
    },
    {
        value: 99.0,
        valueHead: salesValueHead,
        valueTail: salesValueTail,
        label: "William Kim",
        subtitle: "will@email.com",
        image: "/avatars/04.png",
        imageFallback: "WK",
    },
    {
        value: 39.0,
        valueHead: salesValueHead,
        valueTail: salesValueTail,
        label: "Sofia Davis",
        subtitle: "sofia.davis@email.com",
        image: "/avatars/05.png",
        imageFallback: "SD",
    },
];
