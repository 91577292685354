import { IMember, IMemberEditRequest, IMemberRequest, IMembershipChildEditRequest } from "@/modules/module.interface";
import { IRequestedMember } from "../membership.profile-request.store";

// TODO: keep only IMembershipChildEditRequest | IMember
// TODO: rename to requestUpdateMemberWrapper
export const requestEditMemberWrapper = (member: IMember | IRequestedMember | IMembershipChildEditRequest, oldMember: IMember | IMembershipChildEditRequest) => {
    if (!member.email || !oldMember.email) return;
    const value: IMemberEditRequest = {
        ...member,
        member_name: member.member_name,
        email: member.email,
    };
    const old_value: IMemberEditRequest = {
        ...oldMember,
        member_name: oldMember.member_name,
        email: oldMember.email,
    };
    const requestRecord: IMemberRequest = {
        action: "Update",
        module: "membership",
        field: "",
        value: value,
        old_value: old_value,
        created_by: member.email,
    };
    return requestRecord
}
