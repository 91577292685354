import { Button } from "@/components/ui/button"
import { DoulChartCard } from "../charts/chart-wrappers/DoulChart.chartwrapper"
import { NumberCard } from "../charts/chart-wrappers/NumberCard.chartwrapper"
import { NumberCardWithChart } from "../charts/chart-wrappers/NumberCardWithChart.chartwrapper"
import { ChartCard } from "../charts/chart-wrappers/SingleChart.chartwrapper"
import { LineChart } from "../charts/Line.chart"
import { List } from "../charts/ListBar.chart"
import { MiniLineChart } from "../charts/MiniLine.chart"
import { MultiLineChart } from "../charts/MultiLine.chart"
import { MultipleBarChart } from "../charts/MultiBar.chart"
import { lineChartData, sales } from "../data/data"
import {
  monthTargetProps,
  numberOrdersTodayProps,
  totalOrdersTodayProps,
  totalRevenueProps,
} from "../data/numberCards.data"
import { ChartWithSelect } from "../charts/chart-wrappers/ChartWithSelect.chartwrapper"
import { tabs } from "../data/chartWithSelect.data"
import { miniLineConfig } from "../charts/_.chart"

export const Orders: React.FC = () => {
  return <>
    {/*
    * CardsRow
    */}
    <div className="grid gap-4 lg:grid-cols-12">
      {/*
      * MT: Month Target
      */}
      <NumberCard
        {...monthTargetProps}
        className="md:col-span-12 xl:col-span-4"
      >
        <div className="my-auto flex flex-col gap-1">
          <div className="text-2xl font-bold">{"1500BD"}</div>
          <Button>{"See Details"}</Button>
        </div>
      </NumberCard>
      {/*
      * NOT: Number Orders Today
      */}
      <NumberCardWithChart
        {...numberOrdersTodayProps}
      >
        <MiniLineChart data={lineChartData} config={miniLineConfig("Orders")} />
      </NumberCardWithChart>
      {/*
      * TOT: Total Orders Today
      */}
      <NumberCardWithChart
        {...totalOrdersTodayProps}
      >
        <MiniLineChart data={lineChartData} config={miniLineConfig("Orders(BD)")} />
      </NumberCardWithChart>
      {/*
      * TR: Total Revenue
      */}
      <NumberCardWithChart
        {...totalRevenueProps}
        className="md:col-span-4 xl:col-span-2"
      >
        <MiniLineChart data={lineChartData} config={miniLineConfig("Revenue(BD)")} />
      </NumberCardWithChart>
    </div>

    {/*
    * Revenue
    */}
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-8">
      {/*
      * RD&M: Revenue Daily & Monthly
      */}
      <DoulChartCard
        defaultChart="daily"
        title="Revenue Breckdown"
        description="Revenue Daily & Monthly"
        className="md:col-span-4 xl:col-span-5"
        ChartLabel="Daily"
        ChartValue="daily"
        secondChartValue={"monthly"}
        secondChartLabel={"Monthly"}
        secondChart={
          <MultiLineChart />
        }
      >
        <MultiLineChart />
      </DoulChartCard>
      {/*
      * RD&M: Most Sold Items
      */}
      <ChartCard
        title="Most Sold Items"
        description=""
        className="md:col-span-4 xl:col-span-3"
      >
        <List
          data={sales}
        />
      </ChartCard>
    </div>

    {/*
    * Return
    */}
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-12">
      {/*
      * RR: Return Ratio
      */}
      <ChartCard
        title="Return Ratio"
        description="Overall Returns Compared to Overall Sales"
        className="col-span-5"
      >
        <LineChart />
      </ChartCard>
      {/*
      * TCVTR: Total Completed Vs Total Returned
      */}
      <ChartCard
        title="Completed Vs Returned"
        description="Total Orders Completed Vs Total Orders Returned"
        className="col-span-7"
      >
        <MultipleBarChart />
      </ChartCard>
    </div>

    {/*
    * Breakdown
    */}
    <div className="grid gap-4 grid-cols-8">
      {/*
    * MO: Most Ordered
    */}
      <ChartWithSelect
        title="Most Ordered.."
        defaultChart=""
        tabs={tabs}
        className="col-span-4"
      />
      {/*
    * MR: Most Returned
    */}
      <ChartWithSelect
        title="Most Returned.."
        defaultChart=""
        tabs={tabs}
        className="col-span-4"
      />
    </div>
  </>
}
