import React, { Fragment, useEffect } from "react";
import { strings } from "./membership.strings";
import { useBehaviourSubject } from "../../hooks/user-obsevrable";
import { user$ } from "../../state/user";
import { IMember } from "../module.interface";
import _ from "lodash";
import { ProfileCard } from "./components/profile-card";
import { TeamMembersCard } from "./components/team-card";
import { useMembershipStore } from "./store/membership.store";
import { useMembershipProfileRequestStore } from "./store/membership.profile-request.store";
import { RequesteUpdateProfileCard } from "./components/request-update-profile-card";
import { RequestedTeamMembers } from "./components/requested-team-card";
import { useRTeamMember } from "./store/membership.member-request.store";

export type IMembershipDetails = IMember & {
  _id: string;
};
export const MembershipDetails: React.FC = () => {
  const { fetchData } = useMembershipStore();
  const { requestedMember } = useMembershipProfileRequestStore()
  const { rTeamMembers } = useRTeamMember()
  const user = useBehaviourSubject(user$);
  useEffect(() => {
    fetchData(user);
  }, [user, fetchData]);

  return (
    <Fragment>
      {/* Header */}
      <div className="mb-4 flex items-center justify-between space-y-2">
        <h1 className="text-2xl font-bold tracking-tight">{strings.title}</h1>
      </div>
      <div className="grid gap-3 lg:grid-cols-4">
        <div className="space-y-4 lg:col-span-2">
          {requestedMember && <RequesteUpdateProfileCard />}
          <ProfileCard />
        </div>
        <div className="space-y-4 lg:col-span-2">
          {!_.isEmpty(rTeamMembers) && <RequestedTeamMembers />}
          <TeamMembersCard />
        </div>
      </div>
    </Fragment>
  );
};
