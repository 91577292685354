import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar"
import { Star } from "lucide-react" // Import the Star icon from the icon library

// Review data
const reviews = [
    {
        reviewer: "John Doe",
        avatar: "/avatars/john-doe.jpg",
        rating: 5,
        reviewDate: "2024-12-01",
        reviewContent: "Amazing experience! The service was outstanding.",
    },
    {
        reviewer: "Jane Smith",
        avatar: "/avatars/jane-smith.jpg",
        rating: 4,
        reviewDate: "2024-12-02",
        reviewContent: "Great food, but the service could be quicker.",
    },
    {
        reviewer: "Emily Johnson",
        avatar: "/avatars/emily-johnson.jpg",
        rating: 3,
        reviewDate: "2024-12-03",
        reviewContent: "Good ambiance, but the food was average.",
    },
    {
        reviewer: "Michael Brown",
        avatar: "/avatars/michael-brown.jpg",
        rating: 5,
        reviewDate: "2024-12-04",
        reviewContent: "Absolutely loved it! Will definitely come again.",
    },
    {
        reviewer: "Sarah Wilson",
        avatar: "/avatars/sarah-wilson.jpg",
        rating: 4,
        reviewDate: "2024-12-05",
        reviewContent: "Nice place, but a bit crowded during peak hours.",
    },
    {
        reviewer: "David Lee",
        avatar: "/avatars/david-lee.jpg",
        rating: 2,
        reviewDate: "2024-12-06",
        reviewContent: "Not up to expectations. The food was cold.",
    },
    {
        reviewer: "Sophia Martinez",
        avatar: "/avatars/sophia-martinez.jpg",
        rating: 5,
        reviewDate: "2024-12-07",
        reviewContent: "Perfect experience! Highly recommend.",
    },
]

// Function to render stars using icons
const StarRating: React.FC<{ rating: number }> = ({ rating }) => {
    return (
        <div className="flex items-center gap-2 *:size-3">
            {[1, 2, 3, 4, 5].map((star) => (
                <Star
                    key={star}
                    className={star <= rating ? "fill-orange-400 stroke-orange-400" : "fill-none stroke-orange-400"}
                />
            ))}
        </div>
    )
}

export function TableDemo() {
    return (
        <div className="overflow-y-auto max-h-[300px]">
            <Table >
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-[200px]">Reviewer</TableHead>
                        <TableHead>Rating</TableHead>
                        <TableHead>Review Date</TableHead>
                        <TableHead>Review</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {reviews.map((review) => (
                        <TableRow key={review.reviewDate + review.reviewer}>
                            <TableCell className="flex items-center space-x-2">
                                <Avatar>
                                    <AvatarImage
                                        src={review.avatar}
                                        alt={`${review.reviewer}'s avatar`}
                                    />
                                    <AvatarFallback>
                                        {review.reviewer
                                            .split(" ")
                                            .map((word) => word[0])
                                            .join("")}
                                    </AvatarFallback>
                                </Avatar>
                                <span className="font-medium">{review.reviewer}</span>
                            </TableCell>
                            <TableCell><StarRating rating={review.rating} /></TableCell>
                            <TableCell>{review.reviewDate}</TableCell>
                            <TableCell>{review.reviewContent}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}
