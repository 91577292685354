import { INumberCard } from "../charts/chart-wrappers/NumberCard.chartwrapper";
import { INumberCardWithChart } from "../charts/chart-wrappers/NumberCardWithChart.chartwrapper";

const monthTargetProps: INumberCard = {
  title: "Month Target 🎯",
  description: "20% of Target",
  withBG: true,
  bgPath: "images/dashboard/star-shape.png",
};
const numberOrdersTodayProps: INumberCardWithChart = {
  title: "Number Orders Today",
  description: "Total Number of Today's Orders",
  content: "20 Order",
};
const totalOrdersTodayProps: INumberCardWithChart = {
  title: "Total Orders Today",
  description: "Total Value of Today's Orders",
  content: "500BD",
};
const totalRevenueProps: INumberCardWithChart = {
  title: "Total Revenue Today",
  description: "",
  content: "200BD",
};

export {
  monthTargetProps,
  numberOrdersTodayProps,
  totalOrdersTodayProps,
  totalRevenueProps,
};
