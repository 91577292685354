import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import _ from 'lodash';
import { IMember, IMembershipChildEditRequest, IMembershipChildRequest } from '@/modules/module.interface';
import { toast } from '@/state/snackbar';
import { DeleteRequestRecord, GetRequestRecordByUser, UpdateRequestRecord } from '@/services/request.service';
import { requestEditMemberWrapper } from './utils/request-wrapper';

type ITeamMemberView = IMembershipChildEditRequest & {
    message?: string
}
interface IRTeamMember {
    rTeamMembers: ITeamMemberView[] | [];
    setRTeamMembers: (rTeamMembers: ITeamMemberView[]) => void;
    rTeamMember: IMembershipChildEditRequest | undefined;
    setRTeamMember: (activeTeamMemberRequest: IMembershipChildEditRequest | undefined) => void;
    oldRTeamMember: IMembershipChildEditRequest | undefined;
    setOldRTeamMember: (activeTeamMemberRequest: IMembershipChildEditRequest | undefined) => void;
    isEditRTeamMemberOpen: boolean;
    setIsEditRTeamMemberOpen: (isEditRTeamMemberOpen: boolean) => void;
    handleChange: (value: any, field: string) => void
    getPreviousTeamMemberRequests: (member_id: string) => IMember;
    handleEditeRTeamMember: () => void;
    handleDeleteRTeamMember: (id: string) => void;
}

export const useRTeamMember = create<IRTeamMember>()(
    devtools((set, get) => ({
        rTeamMembers: [],
        rTeamMember: undefined,
        oldRTeamMember: undefined,
        isEditRTeamMemberOpen: false,

        setRTeamMembers: (rTeamMembers) => set({ rTeamMembers }),
        setRTeamMember: (rTeamMember) => set({ rTeamMember }),
        setOldRTeamMember: (oldRTeamMember) => set({ oldRTeamMember }),
        setIsEditRTeamMemberOpen: (isEditRTeamMemberOpen) => set({ isEditRTeamMemberOpen }),

        handleChange: (value, field) => {
            const { rTeamMember, setRTeamMember } = get();
            if (!rTeamMember) {
                setRTeamMember({ member_name: "", email: "", [field]: value });
                return;
            }
            const updatedMember = { ...rTeamMember, [field]: value };
            setRTeamMember(updatedMember);
        },

        getPreviousTeamMemberRequests: (member_id) => {
            const { setRTeamMembers } = get();
            // TODO: Validation
            if (_.isNumber(member_id)) {
                member_id = (member_id as number).toString()
            }
            // TODO: get both action Create and Edit
            GetRequestRecordByUser(member_id)
                .then((req) => {
                    const membershipChildRequests = req.data as IMembershipChildRequest[];
                    const childRequests = membershipChildRequests.map((childRequest) => ({
                        ...childRequest.value,
                        _id: childRequest._id,
                    }));
                    setRTeamMembers(childRequests);
                })
                .catch((error) => {
                    console.log(error)
                    toast(
                        "Error happened while getting the previous child request",
                        "error"
                    );
                });
        },

        handleEditeRTeamMember: () => {
            const { rTeamMember, oldRTeamMember, rTeamMembers, setRTeamMembers, setRTeamMember, setIsEditRTeamMemberOpen } = get();
            // TODO: Validation
            // TODO: show Error message
            if (!rTeamMember || !oldRTeamMember) return;
            const requestRecord = requestEditMemberWrapper(rTeamMember, oldRTeamMember);
            if (!requestRecord) return;
            if (rTeamMember && rTeamMember._id) {
                const updateRequestRecordProms = {
                    ...requestRecord,
                    _id: rTeamMember._id,
                };
                UpdateRequestRecord(updateRequestRecordProms)
                    .then(() => {
                        toast("Request updated successfully", "success")
                        setIsEditRTeamMemberOpen(false)
                        setRTeamMember(undefined)
                        setRTeamMembers(
                            rTeamMembers.map((teamMember) => {
                                if (teamMember._id === rTeamMember._id) {
                                    return rTeamMember;
                                }
                                return teamMember;
                            })
                        )
                    })
                    .catch(
                        (error) => (
                            console.log(error),
                            toast("Error happened while updating the request", "error")
                        )
                    )
            }
        },

        handleDeleteRTeamMember: (id) => {
            const {
                rTeamMembers,
                setRTeamMembers,
            } = get();
            if (!id) return;
            DeleteRequestRecord({ _id: id }).then(() => {
                toast("Request deleted successfully", "success")
                setRTeamMembers(
                    rTeamMembers.filter(
                        (teamMember) => teamMember._id !== id
                    )
                )
            })
        }
    }))
);

