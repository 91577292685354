import React, { Fragment, useEffect } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { isEmpty } from "lodash";
import { PaperTiledWithIcon } from "../../../components/tiled-paper";
import {
  CreateStaffRecord,
  FetchPermissionsList,
} from "../../../../services/settings.service";
import { toast } from "../../../../state/snackbar";
import { IESDoc } from "../../../../app.interface";
import { LoadingButton } from "@mui/lab";
import { useBehaviourSubject } from "../../../../hooks/user-obsevrable";
import { user$ } from "../../../../state/user";
import { RenderUrl } from "../../../../util/cell-renderer/renderers";
import { SetRolePermissions } from "../../../settings/manage_users/views/set-role-permissions.view";
import {
  InternalCreateMemberRecord,
  InternalGetNonParentMembersData,
} from "../../../../services/admin-panel/member.service";
import { ChildsIdsMultiSelect } from "../components/childsIds-multiple-select";

interface ICreateEmployeeController {
  onClose: () => void;
}
export const CreateMemberController: React.FC<ICreateEmployeeController> = ({
  onClose,
}) => {
  const theme = useTheme();
  const user = useBehaviourSubject(user$);
  const [name, setName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [isParent, setIsParent] = React.useState<boolean>(false);
  const [childsIds, setChildsIds] = React.useState<string[]>([]);
  const [childsIdsStructure, setChildsIdsStructure] = React.useState<
    { member_id: string; member_name: string }[]
  >([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [activeStep, setActiveStep] = React.useState<number>(0);
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSubmit = () => {
    if (user) {
      setLoading(true);
      InternalCreateMemberRecord(
        8000,
        name,
        email,
        user.staff_email,
        isParent,
        childsIds
      )
        .then(() => {
          toast("Employee Record Created Successfully", "success");
          onClose();
        })
        .catch(() => {
          toast("Error happened while creating the employee record", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const fetchNoneParentMembers = () => {
    InternalGetNonParentMembersData()
      .then((nonParentMembers) => {
        setChildsIdsStructure(
          nonParentMembers.data.map((v) => {
            return { member_name: v.member_name, member_id: v.member_id };
          })
        );
      })
      .catch(() => {
        toast("Error happened while fetching nonParentMembers", "error");
      });
  };
  useEffect(fetchNoneParentMembers, []);

  return (
    <Fragment>
      <Grid container justifyContent={"space-between"} sx={{ minHeight: 500 }}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Stepper activeStep={activeStep} orientation="vertical">
            <Step>
              <StepLabel>Personal Information</StepLabel>
              <StepContent>
                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      size="small"
                      sx={{
                        "& .MuiInputBase-input": {
                          fontSize: "13px",
                          fontWeight: 500,
                        },
                      }}
                      placeholder="Employee Name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      size="small"
                      sx={{
                        "& .MuiInputBase-input": {
                          fontSize: "13px",
                          fontWeight: 500,
                        },
                      }}
                      placeholder="Email"
                      value={email}
                      type="email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      helperText="This email will be used for login"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>Children</StepLabel>
              <StepContent>
                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <FormControlLabel
                      required
                      control={
                        <Checkbox
                          size="small"
                          sx={{
                            "& .MuiInputBase-input": {
                              fontSize: "13px",
                              fontWeight: 500,
                            },
                          }}
                          onChange={(e) => {
                            setIsParent(e.target.checked);
                          }}
                          checked={isParent}
                        />
                      }
                      label="is Parent?"
                    />
                  </Grid>
                  {/* child id */}
                  <ChildsIdsMultiSelect
                    isParent={isParent}
                    setChildsIds={setChildsIds}
                    options={childsIdsStructure.map((x) => x.member_id)}
                    selectedOptions={childsIds}
                  />
                </Grid>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>Location</StepLabel>
              <StepContent></StepContent>
            </Step>
            <Step>
              <StepLabel>Socials</StepLabel>
              <StepContent></StepContent>
            </Step>
          </Stepper>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{ justifyContent: "flex-end", display: "flex" }}
        >
          <Box
            sx={{
              border: `2px solid ${theme.palette.primary.main}`,
              borderRadius: 2,
              minHeight: 400,
              width: "80%",
              mt: 2,
            }}
          >
            <Stack spacing={2} sx={{ padding: 2, position: "relative" }}>
              <Box
                sx={{
                  position: "absolute",
                  top: -40,
                  right: "0px",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Grid container justifyContent={"center"}>
                  <Grid item>
                    <Avatar
                      sx={{
                        width: 80,
                        height: 80,
                        backgroundColor: theme.palette.primary.main,
                        fontWeight: 600,
                        border: `10px solid ${theme.palette.background.paper}`,
                      }}
                    >
                      {isEmpty(name)
                        ? "??"
                        : `${!isEmpty(name) ? name[0] : ""}`}
                    </Avatar>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ paddingTop: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <PaperTiledWithIcon
                      color="primary"
                      icon="badge"
                      isDefault
                      loading={false}
                      title="Employee Name"
                      value={name}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <PaperTiledWithIcon
                      color="primary"
                      icon="email"
                      isDefault
                      loading={false}
                      title="Employee Email"
                      value={email}
                    />
                  </Grid>
                  {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                    <PaperTiledWithIcon
                      color="primary"
                      icon="lock"
                      isDefault
                      loading={false}
                      title="Password"
                      value={password}
                      isMasked={true}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <SetRolePermissions
                      roleName={""}
                      permissionsStructureList={permissionsStructure}
                      resultedPermissions={resultedPermissions}
                      setResultedPermissions={setResultedPermissions}
                      miniView={true}
                      editView={false}
                      resultView={true}
                      isUpdating={true}
                    />
                  </Grid> */}
                </Grid>
              </Box>
            </Stack>
          </Box>
        </Grid>
        <Grid container justifyContent={"flex-start"} spacing={2}>
          <Grid item>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              disabled={activeStep === 3 && (isEmpty(name) || isEmpty(email))}
              loading={activeStep === 3 && loading}
              onClick={activeStep === 3 ? handleSubmit : handleNext}
            >
              {activeStep === 3 ? "Submit" : "Next"}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};
