import React, { Fragment } from "react";
import { useBehaviourSubject } from "../../hooks/user-obsevrable";
import {
  selectedMember$,
  user$,
} from "../../state/user";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useMemberSelectorStore } from "./header.member-selector.store";
import { Spinner } from "@/components/ui/spinner";
import { MemberSwitcher } from "./components/meber-switcher";

export const HeaderMemberSelector: React.FC = () => {
  const user = useBehaviourSubject(user$);
  const selectedMember = useBehaviourSubject(selectedMember$);
  const { docs, loading, fetchData } = useMemberSelectorStore();
  useDeepCompareEffect(() => (console.log("hkj:"), fetchData(user)), [user, selectedMember]);
  console.log({ selectedMember });
  console.log({ docs });
  return (
    <Fragment>
      <div className="flex items-center gap-2">
        {loading ?
          <div className="flex justify-center items-center w-[350px] h-[40px]">
            <Spinner />
          </div> : docs.length > 0 &&
          <MemberSwitcher teams={docs} />
        }
      </div>
    </Fragment >
  );
};
