import React from "react"

import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import { IChartCard } from "./SingleChart.chartwrapper"
import { _NumberCardClassName } from "./_.chartwrapper"

export type INumberCardWithChart = IChartCard & {
    content: string,
    withBG?: boolean,
    bgPath?: string
    variant?: "default" | "fill"
}
export const NumberCardWithChart: React.FC<INumberCardWithChart> = ({
    title,
    description,
    content,
    children,
    withBG = false,
    bgPath,
    variant = "default",
    className = "md:col-span-4 xl:col-span-3",
}) => {
    return <>
        <Card className={`${_NumberCardClassName} ${className}`}>
            {withBG && bgPath && <div
                className="absolute inset-0 bg-cover bg-center z-0"
                style={{
                    backgroundImage: `url('${bgPath}?height=400&width=300')`,
                }}
            />}
            <CardHeader className="pb-3">
                <CardTitle className="text-base font-normal tracking-tight">
                    {title}
                </CardTitle>
            </CardHeader>
            <CardContent className="p-0">
                <div className="px-6 text-2xl font-bold">
                    {content}
                </div>
                <CardDescription className="px-6 text-xs text-muted-foreground">
                    {description}
                </CardDescription>

            </CardContent>
            <div className={variant === "fill" ? "pt-2" : "px-6 pt-2"}>
                {children}
            </div>
        </Card>
    </>
}
