import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import __ from "@/lang/__"
import { Field, FieldProps } from "@/modules/_/form/field"
import { DialogProps } from "@radix-ui/react-dialog"
import { ExternalLink, Send } from "lucide-react"

type DialogFormProps = DialogProps & {
  children?: React.ReactNode;
  title: string;
  description?: string;
  onSubmit: () => void;
  fields: FieldProps[];
}
export function DialogForm(props: DialogFormProps) {
  const {
    title,
    description,
    children,
    fields,
    onSubmit,
    ...dilogProps
  } = props
  return (
    <Dialog {...dilogProps}>
      <DialogTrigger asChild>
        {children ? children : <Button variant="outline" size={"icon"}><ExternalLink /></Button>}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          {description && <DialogDescription>
            {description}
          </DialogDescription>}
        </DialogHeader>
        <div className="flex flex-col gap-4 py-4">
          {fields?.map((field) => (
            <div key={field.masterColumnName} className="w-full items-center gap-4">
              <Field
                {...field}
                editMode
              />
            </div>
          ))}
        </div>
        <DialogFooter>
          <Button type="submit" onClick={onSubmit}>
            <Send />
            {__("Submit")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

