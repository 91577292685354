import { DialogForm } from "@/components/dialogs/dialog.form"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import __ from "@/lang/__"
import { cn } from "@/lib/utils"
import { IMember } from "@/modules/module.interface"
import { Pencil, Plus } from "lucide-react"
import { useEffect } from "react"
import { ITeamMemberView, useTeamMembers } from "../store/membership.invite-member.store"
import { useMembershipStore } from "../store/membership.store"

export function TeamMembersCard() {
  const { member } = useMembershipStore()
  const {
    teamMembers,
    setTeamMembers,
    teamMember,
    setTeamMember,
    setOldTeamMember,
    isAddTeamMemberOpen,
    setIsAddTeamMemberOpen,
    isEditTeamMemberOpen,
    setIsEditTeamMemberOpen,
    handleChange,
    getMemberProfile,
    handleSubmitAddMember,
    handleEditTeamMember,
    // handleDeleteTeamMember,
  } = useTeamMembers()

  const onAdd = () => { setIsAddTeamMemberOpen(true); }
  const onEdit = (tm: typeof teamMember) => { setTeamMember(tm); setOldTeamMember((tm as IMember)); setIsEditTeamMemberOpen(true); }

  useEffect(() => {
    if (member && member.childs_ids) {
      const tms: IMember[] = []
      let count = 1
      member.childs_ids.forEach((id) => {
        let m: ITeamMemberView = getMemberProfile(id)
        // TODO: activate it after fixing the DB
        // if (!m || !m.member_id) return
        if (!m?.member_name || !m?.email) {
          m = {
            member_id: id,
            is_parent: false,
            member_name: `Member ${count}`,
            email: `member${count}example.com`,
            message: __("There are important data missing, consider editing the member Email or Name")
          }
          count++
        }
        tms.push(m)
      })
      setTeamMembers(tms)
    }
  }, [member])

  if (!member || !member.childs_ids) return <></>
  return <>
    <Card className="max-h-[500px]">
      <CardHeader>
        <div className="mb-4 flex items-center justify-between space-y-2">
          <CardTitle>Team Memebers</CardTitle>
          <DialogForm
            open={isAddTeamMemberOpen}
            onOpenChange={(open) => setIsAddTeamMemberOpen(open)}
            title="Invite Team Member"
            description="Enter the email address of the team member you would like to invite."
            onSubmit={handleSubmitAddMember}
            fields={[
              {
                loading: false,
                editMode: true,
                masterColumnName: "member_name",
                masterColumnTitle: __("Team Member Name"),
                renderType: "text",
                required: true,
                entity: teamMember,
                handleChange,
              },
              {
                loading: false,
                editMode: true,
                masterColumnName: "email",
                masterColumnTitle: __("Email"),
                renderType: "text",
                required: true,
                entity: teamMember,
                handleChange,
              },
            ]}
          >
            <Button onClick={onAdd}>
              <Plus />Invite
            </Button>
          </DialogForm>

        </div>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {
            teamMembers.map((tm) => (
              <div key={tm.email} className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <span className="relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full">
                    <span className="flex h-full w-full items-center justify-center rounded-full bg-muted">
                      {tm.member_name.split(" ").map((n) => n[0]).join("")}
                    </span>
                  </span>
                  <div className="w-[80%]">
                    <div className="font-medium">{tm.member_name}</div>
                    <div className="text-xs text-muted-foreground">{tm.email}</div>
                    {tm.message && <div className="text-xs text-destructive">{tm.message}</div>}
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <DialogForm
                    open={tm == teamMember && isEditTeamMemberOpen}
                    onOpenChange={(open) => setIsEditTeamMemberOpen(open)}
                    title="Edit Team Member"
                    description="edit"
                    onSubmit={handleEditTeamMember}
                    fields={[
                      {
                        loading: false,
                        editMode: true,
                        masterColumnName: "member_name",
                        masterColumnTitle: __("Team Member Name"),
                        renderType: "text",
                        required: true,
                        entity: teamMember,
                        handleChange,
                      },
                      {
                        loading: false,
                        editMode: true,
                        masterColumnName: "email",
                        masterColumnTitle: __("Email"),
                        renderType: "text",
                        required: true,
                        entity: teamMember,
                        handleChange,
                      },
                    ]}
                  >

                    <div className="relative inline-block">
                      <Button onClick={() => onEdit(tm)} variant={"outline"} className={cn("relative z-10 focus:outline-none", tm.message && "border-destructive text-destructive")}>
                        <Pencil />
                      </Button>
                      {tm.message && <div
                        className={"absolute inset-0 z-0 w-full h-full rounded-md bg-destructive opacity-50 animate-blooming"}
                      />}
                    </div>
                  </DialogForm>
                  {/* TODO: Delete Member
                    <Button variant={"destructive"}>
                      <Trash2 />
                    </Button>
                  */}
                </div>
              </div>
            ))
          }
        </div>
      </CardContent>
    </Card>
  </>
}

