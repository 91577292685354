import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import _ from 'lodash';
import { toast } from '@/state/snackbar';
import { IMember, IRequestRecordKey } from '@/modules/module.interface';
import { DeleteRequestRecord, GetRequestRecordByKey, UpdateRequestRecord } from '@/services/request.service';
import { useMembershipStore } from './membership.store';
import { requestEditMemberWrapper } from './utils/request-wrapper';

export type IRequestedMember = Pick<IMember, "member_name" | "email"> & {
    _id: string;
}

interface MembershipStore {
    // Request Edit Profile
    requestedMember: IRequestedMember | undefined;
    oldRequestedMember: IRequestedMember | undefined;
    setRequestedMember: (requestedMember: IRequestedMember | undefined) => void;
    isEditRequested: boolean;
    setIsEditRequested: (isEditRequested: boolean) => void;
    hasChangedRequested: boolean;
    setHasChangedRequested: (hasChangedRequested: boolean) => void;
    handleChangeRequested: (value: string, field: string) => void;
    handleSubmitEdit: () => void; // Create
    getPreviousEditRequest: () => void; // Read
    handleEditPreviousRequest: () => void; // Update
    handleDeletePreviousRequest: () => void; // Delete
}

export const useMembershipProfileRequestStore = create<MembershipStore>()(
    devtools((set, get) => ({
        requestedMember: undefined,
        oldRequestedMember: undefined,
        isEditRequested: false,
        hasChangedRequested: false,

        setRequestedMember: (requestedMember) => set({ requestedMember }),
        setIsEditRequested: (isEditRequested) => set({ isEditRequested }),
        setHasChangedRequested: (hasChangedRequested) => set({ hasChangedRequested }),
        handleChangeRequested: (value, field) => {
            const { requestedMember, setRequestedMember } = get();
            if (!requestedMember) return;
            const updatedMember = { ...requestedMember, [field]: value };
            setRequestedMember(updatedMember);
        },

        getPreviousEditRequest: () => {
            const { member } = useMembershipStore.getState()
            const { setRequestedMember } = get();
            if (member === undefined || member.email === undefined) {
                toast("You do not have a member email, please set it first");
                return;
            }
            const requestKey: IRequestRecordKey = {
                module: "membership",
                action: "Update",
                created_by: member.email,
            };
            GetRequestRecordByKey(requestKey)
                .then((res) => {
                    console.log("res", res)
                    const requestedMember = { ...res.data[0].value, _id: res.data[0]._id }
                    setRequestedMember(requestedMember);
                    set({ oldRequestedMember: requestedMember })
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        handleEditPreviousRequest: () => {
            const { member, setSubmitCounter } = useMembershipStore.getState();
            const {
                requestedMember,
                setIsEditRequested,
            } = get();
            setSubmitCounter(true);
            if (
                requestedMember === undefined ||
                requestedMember.email === undefined ||
                requestedMember.member_name === undefined ||
                member === undefined ||
                member.email === undefined ||
                member.member_name === undefined
            ) {
                toast("Name or Email is empty", "error");
                return;
            }
            const requestRecord = requestEditMemberWrapper(requestedMember, member);
            if (!requestRecord) return;
            if (requestedMember && requestedMember._id) {
                const updateRequestRecordProms = {
                    ...requestRecord,
                    _id: requestedMember._id,
                };
                UpdateRequestRecord(updateRequestRecordProms)
                    .then(
                        (res) => (
                            toast("Request updated successfully", "success"),
                            console.log(res)
                        )
                    )
                    .catch(
                        (error) => (
                            console.log(error),
                            toast("Error happened while updating the request", "error")
                        )
                    )
                    .finally(() => {
                        setSubmitCounter(false);
                        setIsEditRequested(false);
                    });
            }
        },
        handleDeletePreviousRequest: () => {
            const { requestedMember } = get();
            if (requestedMember && requestedMember._id) {
                DeleteRequestRecord({ _id: requestedMember._id })
            }
        },
    }))
);

