import { NumberCard } from "../charts/chart-wrappers/NumberCard.chartwrapper"
import { NumberCardWithChart } from "../charts/chart-wrappers/NumberCardWithChart.chartwrapper"
import { ChartCard } from "../charts/chart-wrappers/SingleChart.chartwrapper"
import { LineChart } from "../charts/Line.chart"
import { MiniAreaChart } from "../charts/Area.chart"
import { ProgressChart } from "../charts/statics/progress.chart"
import { TableDemo } from "../charts/Table.chart"
import { PieChart } from "../charts/PieChart.chart"
import { CustomerReviewCard } from "../charts/statics/startRating.chart"
import { MultipleBarChart } from "../charts/MultiBar.chart"

export const Stock: React.FC = () => {
  return <>
    {/*
    * Reviews
    */}
    <div className="grid gap-4 lg:grid-cols-12">
      {/*
      * SBB: Stock by Brand
      */}
      <ChartCard
        title="Stock by Brand"
        description=""
        className="md:col-span-4 xl:col-span-4"
      >
        <PieChart/>
      </ChartCard>
      {/*
      * SBC: Stock by Category
      */}
      <ChartCard
        title="Stock by Category"
        description=""
        className="md:col-span-4 xl:col-span-4"
      >
        <PieChart/>
      </ChartCard>
      {/*
      * SBB: Stock by Condition
      */}
      <ChartCard
        title="Stock by Condition"
        description=""
        className="md:col-span-4 xl:col-span-4"
      >
        <PieChart/>
      </ChartCard>
    </div>

    {/*
    * Current Stock
    */}
    <div className="grid gap-4 grid-cols-8">
      {/*
      * CSVO: Current Stock vs last Month Ordered
      */}
      <ChartCard
        title="Current Stock vs last Month Ordered"
        description=""
        className="col-span-8"
        >
        <MultipleBarChart />
      </ChartCard>
    </div>
  </>
}
