import { Button } from "@/components/ui/button";
import { Bell } from "lucide-react";
import React, { Fragment } from "react";
import { AppDrawer } from "../Drawer";
import { NotificationsView } from "../header-notifications/notifications-drawer.view";

export const NotificationsHeader: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  return (
    <Fragment>
      <Button onClick={() => setIsOpen(true)} variant="ghost" size="icon" className="relative">
        <Bell className="h-5 w-5" />
        <span className="absolute top-0 right-0 h-2 w-2 rounded-full bg-primary" />
      </Button>
      {isOpen ? (
        <AppDrawer
          anchor={"right"}
          open={true}
          title={"Notifications"}
          subTitle={"Find out what is new"}
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <NotificationsView />
        </AppDrawer>
      ) : null}
    </Fragment>
  );
};
