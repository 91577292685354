import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs"
import { Orders } from "./tabs/dashboard.tabs.orders"
import { Customers } from "./tabs/dashboard.tabs.cutomers"
import { Stock } from "./tabs/dashboard.tabs.stock"

type ITabData = {
  label: string
  value: string
  children: React.ReactNode
  disabled?: boolean
}
const TabsData: ITabData[] = [
  {
    label: "Orders",
    value: "orders",
    children: <Orders />
  },
  {
    label: "Customers",
    value: "customers",
    children: <Customers />,
  },
  {
    label: "Stock",
    value: "stock",
    children: <Stock />,
  },
]

export default function DashboardPage() {
  return (
    <>
      <div className="md:hidden">
      </div>
      <div className="hidden flex-col md:flex">
        <div className="flex-1 space-y-4 p-8 pt-6">
          {/*
            * Tabs
            */}
          <Tabs defaultValue="orders" className="space-y-4">
            <div className="flex items-center gap-4 justify-start space-y-2">
              <h2 className="text-3xl font-bold tracking-tight">Dashboard</h2>
              <TabsList>
                {TabsData.map(({label, value, disabled}) => (
                  <TabsTrigger
                    key={value}
                    value={value}
                    className="text-sm font-medium"
                    disabled={disabled}
                  >
                    {label}
                  </TabsTrigger>
                ))}
              </TabsList>
            </div>
            {TabsData.map((tab) => (
              <TabsContent
                key={tab.value}
                value={tab.value}
                className="space-y-4"
              >
                {tab.children}
              </TabsContent>
            ))}
          </Tabs>
        </div>
      </div>
    </>
  )
}


