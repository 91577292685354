import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ISelectedMember, user$ } from '@/state/user';
import { useBehaviourSubject } from '@/hooks/user-obsevrable';
import { FetchAllowedMemberData } from '@/services/user.service';
import { toNumber } from 'lodash';
import { IESDoc } from '@/app.interface';
import { toast } from '@/state/snackbar';

type MemberSelectorStore = {
  docs: ISelectedMember[],
  loading: boolean,
  fetchData: (user: any) => void
}
export const useMemberSelectorStore = create<MemberSelectorStore>()(
  devtools((set, get) => ({
    docs: [],
    loading: true,
    fetchData: (user: any) => {
      console.log({ user })
      console.log("fknin")
      if (!user) return
      if (user.is_admin_account) {
        set({ loading: true })
        FetchAllowedMemberData()
          .then((res) => {
            const docsList: ISelectedMember[] = [
              {
                member_id: toNumber(user.member_id),
                member_name: user.member_name,
                is_admin_account: true,
              },
              ...res.data.map((org: IESDoc) => ({
                member_id: org.member_id,
                member_name: org.member_name,
                is_admin_account: false,
              })),
            ];
            set({ docs: docsList })
          })
          .catch(() => {
            toast("Error happened while fetching members data", "error");
          })
          .finally(() => {
            set({ loading: false })
          });
      } else {
        set({
          docs: [
            { member_id: toNumber(user.member_id), member_name: user.member_name, is_admin_account: false },
          ]
        })
        set({ loading: false })
      }
    }
  }))
)
