import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { Card, CardContent } from "@/components/ui/card"
import __ from "@/lang/__"
import { cn } from "@/lib/utils"
import { Field } from "@/modules/_/form/field"
import _ from "lodash"
import { Pencil, Send, Trash2, X } from "lucide-react"
import { useEffect } from "react"
import { IMembershipDetails } from "../membership.shadcn.index"
import { useMembershipProfileRequestStore } from "../store/membership.profile-request.store"
import { useMembershipStore } from "../store/membership.store"

export type ProfileCardProps = {
  member: IMembershipDetails | undefined
}
export function RequesteUpdateProfileCard() {
  const { loading } = useMembershipStore();
  const {
    requestedMember,
    oldRequestedMember,
    setRequestedMember,
    isEditRequested,
    setIsEditRequested,
    hasChangedRequested,
    handleChangeRequested,
    setHasChangedRequested,
    handleEditPreviousRequest,
    handleDeletePreviousRequest,
  } = useMembershipProfileRequestStore()

  const onEdit = () => setIsEditRequested(true)
  const onCancel = () => { setIsEditRequested(false); setRequestedMember(oldRequestedMember) }
  const onSubmit = () => { handleEditPreviousRequest(); setIsEditRequested(false) }
  const onDelete = () => { handleDeletePreviousRequest(); setIsEditRequested(false); setRequestedMember(undefined) }
  useEffect(() => {
    if (!_.isEqual(requestedMember, oldRequestedMember)) {
      setHasChangedRequested(true);
    } else {
      setHasChangedRequested(false);
    }
  }, [requestedMember, oldRequestedMember]);
  console.log("requestedMember", requestedMember)
  return <>
    <Card className="border-upcoming">
      <CardContent className="p-6 relative ">
        <div className="flex flex-col items-center gap-1">
          <div className="w-full flex justify-between">
            <div className="">
              <div className="font-semibold">{__("Requested New Profile")}
                <Badge variant={"outline"} className="inline mx-1 border-upcoming text-upcoming bg-upcoming/20">{__("Waiting Scrap Team Approval")}</Badge>
              </div>
            </div>
            <div className="flex items-center gap-4">
              {isEditRequested ?
                <>
                  <Button size={"icon"} variant={"destructive"} onClick={onCancel}>
                    <X />
                  </Button>
                  <Button disabled={!hasChangedRequested} onClick={onSubmit}>
                    <Send />
                    {__("Submit")}
                  </Button>
                </> :
                <>
                  <Button size={"icon"} variant={"outline"} onClick={onEdit}>
                    <Pencil />
                  </Button>
                  <Button size={"icon"} variant={"destructive"} onClick={onDelete} >
                    <Trash2 />
                  </Button>

                </>}
            </div>
          </div>
          <div className="flex flex-col items-center space-y-4">
            <span className="relative flex shrink-0 overflow-hidden rounded-full h-20 w-20">
              {/*TODO: image input*/}
              <img
                className="aspect-square h-full w-full"
                alt="@shadcn"
                src="/avatar.png"
              />
            </span>
            <div className={cn("text-center", isEditRequested && "flex flex-col gap-2 min-w-[400px]")}>
              <Field
                hideTitle
                editMode={isEditRequested}
                loading={loading}
                entity={requestedMember}
                renderType="text"
                masterColumnName="member_name"
                masterColumnTitle="Company Name"
                handleChange={handleChangeRequested}
                customRender={(entity) => (
                  <h5 className="text-lg font-semibold">{entity?.member_name}</h5>
                )}
              />
              <Field
                hideTitle
                editMode={isEditRequested}
                loading={loading}
                entity={requestedMember}
                renderType="text"
                masterColumnName="email"
                masterColumnTitle="Company Email"
                handleChange={handleChangeRequested}
                customRender={(entity) => (
                  <div className="text-sm text-muted-foreground">
                    {entity?.email}
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  </>
}

