import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader } from "@/components/ui/card"
import __ from "@/lang/__"
import { cn } from "@/lib/utils"
import { Field } from "@/modules/_/form/field"
import { Instagram, WhatsApp } from "@mui/icons-material"
import _ from "lodash"
import { Pencil, Phone, Send, X } from "lucide-react"
import { useEffect } from "react"
import { IMembershipDetails } from "../membership.shadcn.index"
import { useMembershipProfileRequestStore } from "../store/membership.profile-request.store"
import { useMembershipStore } from "../store/membership.store"
import { RequesteUpdateProfileCard } from "./request-update-profile-card"

export type ProfileCardProps = {
  member: IMembershipDetails | undefined
}
export function ProfileCard() {
  const {
    loading,
    member,
    oldMember,
    setMember,
    isEdit,
    setIsEdit,
    hasChanged,
    setHasChanged,
    handleChange,
    handleSubmit,
  } = useMembershipStore();

  const {
    requestedMember,
  } = useMembershipProfileRequestStore()
  const onEdit = () => setIsEdit(true)
  const onCancel = () => { setIsEdit(false); setMember(oldMember) }
  const onSubmit = () => { handleSubmit(); setIsEdit(false) }
  useEffect(() => {
    if (!_.isEqual(member, oldMember)) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
  }, [member, oldMember]);

  return <>
    <Card >
      <CardHeader >
        {!isEdit &&
          <div className="flex justify-between">
            <Badge >Pro</Badge>
            < Button onClick={onEdit} variant={"outline"}>
              <Pencil />
              {__("Edit")}
            </Button>
          </div>
        }
        {isEdit &&
          <div className="flex justify-between">
            <Button disabled={!hasChanged} onClick={onSubmit}>
              <Send />
              {__("Submit")}
            </Button>
            <div className="flex gap-2">
              <Button variant={"destructive"} onClick={onCancel} >
                <X />
                {__("Cancel")}
              </Button>
            </div>
          </div>
        }
      </CardHeader>
      <CardContent className="p-6 ">
        <div className="space-y-12">
          <div className="flex flex-col items-center space-y-4">
            <span className="relative flex shrink-0 overflow-hidden rounded-full h-20 w-20">
              {/*TODO: image input*/}
              <img
                className="aspect-square h-full w-full"
                alt="@shadcn"
                src="/avatar.png"
              />
            </span>
            <div className={cn("text-center", isEdit && "flex flex-col gap-2 min-w-[400px]")}>
              <Field
                hideTitle
                editMode={isEdit}
                loading={loading}
                entity={member}
                renderType="text"
                masterColumnName="member_name"
                masterColumnTitle="Company Name"
                handleChange={handleChange}
                customRender={(entity) => (
                  <h5 className="text-lg font-semibold">{entity?.member_name}</h5>
                )}
              />
              {isEdit && <span className="-mt-1 text-sm text-muted-foreground">{__("Changing this field will require Scrap team approval")}</span>}
              <Field
                hideTitle
                editMode={isEdit}
                loading={loading}
                entity={member}
                renderType="text"
                masterColumnName="email"
                masterColumnTitle="Company Email"
                handleChange={handleChange}
                customRender={(entity) => (
                  <div className="text-sm text-muted-foreground">
                    {entity?.email}
                  </div>
                )}
              />
              {isEdit && <span className="-mt-1 text-sm text-muted-foreground">{__("Changing this field will require Scrap team approval")}</span>}
            </div>
          </div>
          {/* TODO: get value from DB */}
          <div className="grid grid-cols-3 divide-x text-center">
            <div>
              <h5 className="text-lg font-semibold">4.5</h5>
              <div className="text-sm text-muted-foreground">{__("Rate")}</div>
            </div>
            <div>
              <h5 className="text-lg font-semibold">32</h5>
              <div className="text-sm text-muted-foreground">{__("Member")}</div>
            </div>
            <div>
              <h5 className="text-lg font-semibold">4.5K</h5>
              <div className="text-sm text-muted-foreground">{__("Item")}</div>
            </div>
          </div>
          <div className="flex flex-col gap-y-4">
            <Field
              editMode={isEdit}
              loading={loading}
              entity={member}
              renderType="text"
              masterColumnName="address"
              masterColumnTitle="Address"
              handleChange={handleChange}
            />
            <Field
              editMode={isEdit}
              loading={loading}
              entity={member}
              renderType="text"
              masterColumnName="category"
              masterColumnTitle="Category"
              handleChange={handleChange}
            />
            <Field
              editMode={isEdit}
              loading={loading}
              entity={member}
              renderType="text"
              masterColumnName="type"
              masterColumnTitle="Type"
              handleChange={handleChange}
            />
            <div className="mt-1 flex flex-col items-start gap-2">
              <div className="font-semibold">
                {__("Social Media")}
              </div>
              <div className="flex flex-col gap-3 item-start ml-3 ">
                <div className="flex items-center gap-3">
                  <Phone className="h-4 w-4" />
                  <Field
                    hideTitle
                    editMode={isEdit}
                    loading={loading}
                    entity={member?.socials}
                    renderType="text"
                    masterColumnName="phone_number"
                    masterColumnTitle="Phone Number"
                    handleChange={handleChange}
                  />
                </div>
                <div className="flex items-center gap-3">
                  <Instagram className="h-5 w-5" />
                  <Field
                    hideTitle
                    editMode={isEdit}
                    loading={loading}
                    entity={member?.socials}
                    renderType="text"
                    masterColumnName="instagram"
                    masterColumnTitle="Instagram"
                    handleChange={handleChange}
                  />
                </div>
                <div className="flex items-center gap-3">
                  <WhatsApp className="h-5 w-5" />
                  <Field
                    hideTitle
                    editMode={isEdit}
                    loading={loading}
                    entity={member?.socials}
                    renderType="text"
                    masterColumnName="whatsapp"
                    masterColumnTitle="WhatsApp"
                    handleChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  </>
}

