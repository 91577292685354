import * as React from "react"
import { ChevronsUpDown, Plus } from "lucide-react"

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { ISelectedMember } from "@/state/user"
import __ from "@/lang/__"

export function MemberSwitcher({ teams }: { teams: ISelectedMember[] }) {
  const [activeTeam, setActiveTeam] = React.useState(teams[0])
  return (
    teams.length > 1 ?
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div
            className="min-w-[15rem] min-h-8 flex items-center gap-2 rounded-lg border px-3 py-1 shadow-sm"
          >
            {/*{activeTeam.logo && <div className="flex aspect-square size-8 items-center justify-center rounded-lg">
            <activeTeam.logo className="size-4" />
          </div>}*/}
            <div className="grid flex-1 text-left text-sm leading-tight">
              <span className="truncate font-semibold">
                {activeTeam.member_name}
              </span>
            </div>
            <ChevronsUpDown className="ml-auto" />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
          align="start"
          side={"bottom"}
          sideOffset={4}
        >
          <DropdownMenuLabel className="text-xs text-muted-foreground">
            {__("Comapny")}
          </DropdownMenuLabel>
          {teams.map((team) => (
            <DropdownMenuItem
              key={team.member_name}
              onClick={() => setActiveTeam(team)}
              className="gap-2 p-2"
            >
              {/*{team.logo && <div className="flex size-6 items-center justify-center rounded-sm border">
              <team.logo className="size-4 shrink-0" />
            </div>}*/}
              {team.member_name}
            </DropdownMenuItem>
          ))}
          {/* <DropdownMenuSeparator />
          <DropdownMenuItem className="gap-2 p-2"><DropdownMenuItem className="gap-2 p-2">
            <div className="flex size-6 items-center justify-center rounded-md border bg-background">
              <Plus className="size-4" />
            </div>
            <div className="font-medium text-muted-foreground">Add team</div>
          </DropdownMenuItem> */}
        </DropdownMenuContent>
      </DropdownMenu> :
      <div
        className="min-w-[15rem] min-h-8 flex items-center gap-2 rounded-lg border px-3 py-1 shadow-sm"
      >
        {/*{activeTeam.logo && <div className="flex aspect-square size-8 items-center justify-center rounded-lg">
            <activeTeam.logo className="size-4" />
          </div>}*/}
        <div className="grid flex-1 text-left text-sm leading-tight">
          <span className="truncate font-semibold">
            {activeTeam.member_name}
          </span>
        </div>
      </div>
  )
}
