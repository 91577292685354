import { NumberCard } from "../charts/chart-wrappers/NumberCard.chartwrapper"
import { NumberCardWithChart } from "../charts/chart-wrappers/NumberCardWithChart.chartwrapper"
import { ChartCard } from "../charts/chart-wrappers/SingleChart.chartwrapper"
import { LineChart } from "../charts/Line.chart"
import { MiniAreaChart } from "../charts/Area.chart"
import { ProgressChart } from "../charts/statics/progress.chart"
import { TableDemo } from "../charts/Table.chart"
import { PieChart } from "../charts/PieChart.chart"
import { CustomerReviewCard } from "../charts/statics/startRating.chart"

export const Customers: React.FC = () => {
  return <>
    {/*
    * CardsRow
    */}
    <div className="grid gap-4 lg:grid-cols-12">
      {/*
      * CO: Current Offer
      */}
      <NumberCard
        title="Current Offer Interactions"
        withBG
        bgPath="images/dashboard/offer.jpg"
        description=""
        className="md:col-span-12 xl:col-span-4"
        imageClassName="opacity-10"
      >
        <div className="my-auto flex flex-row justify-between w-full font-bold">
          <div className=" flex items-center justify-end gap-3">
            <div className="w-12 rounded-lg px-2 py-1 text-center bg-[hsl(var(--chart-1))]/70">
              55
            </div>
            <span>Visits</span>
          </div>
          <div className="flex items-center justify-end gap-3">
            <div className="w-12 rounded-lg px-2 py-1 text-center bg-[hsl(var(--chart-2))]/70">
              33
            </div>
            <span>Orders</span>
          </div>
          <div className="flex items-center justify-end gap-3">
            <div className="w-12 rounded-lg px-2 py-1 text-center bg-[hsl(var(--chart-3))]/70">
              2
            </div>
            <span>Reviews</span>
          </div>
        </div>
      </NumberCard>
      {/*
      * AVT: Avrage Visits Today
      */}
      <NumberCardWithChart
        title="Avrage Visits Today"
        description=""
        content="250 Visit"
        className="md:col-span-12 xl:col-span-4"
        variant="fill"
      >
        <MiniAreaChart />
      </NumberCardWithChart>
      {/*
      * TOT: Total Orders Today
      */}
      <NumberCardWithChart
        title="Orders VS Visits"
        description="Total Visits compared to Total Orders this month"
        content="755 Visit"
        className="md:col-span-12 xl:col-span-4"
      >
        <ProgressChart
          orderPercentage={75.2}
          visitPercentage={25.5}
        />
      </NumberCardWithChart>
    </div>

    {/*
    * Reviews
    */}
    <div className="grid gap-4 lg:grid-cols-12">
      {/*
      * CR: Customer Reviews
      */}
      <ChartCard
        title="Customer Reviews"
        description=""
        className="md:col-span-4 xl:col-span-4"
      >
        <CustomerReviewCard />
      </ChartCard>
      {/*
      * RR: Recent Reviews
      */}
      <ChartCard
        title="Recent Reviews"
        description=""
        className="md:col-span-4 xl:col-span-8"
      >
        <TableDemo />
      </ChartCard>
    </div>

    {/*
    * Pickes
    */}
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-12">
      {/*
      * OPT: Orders Peak Time
      */}
      <ChartCard
        title="Orders Peak Time"
        description=""
        className="md:col-span-4 xl:col-span-8"
        >
        <LineChart className="max-h-[400px]" />
      </ChartCard>
      {/*
      * RD&M: Top Ordered Cities
      */}
      <ChartCard
        title="Top Ordered Cities"
        description=""
        className="md:col-span-4 xl:col-span-4"
      >
        <PieChart />
      </ChartCard>
    </div>
  </>
}
