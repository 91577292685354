import { Pie, PieChart as PieChart_R } from "recharts"
import {
    ChartContainer,
    ChartLegend,
    ChartLegendContent,
    ChartTooltip,
    ChartTooltipContent,
} from "@/components/ui/chart"
import { chartConfig, chartData } from "../data/data"
import { chartContainerClassName } from "./_.chart"

export function PieChart() {
    return (
        <ChartContainer
            config={chartConfig}
            className={`${chartContainerClassName} max-h-[400px] mx-auto aspect-square`}
        >
            <PieChart_R>
                <Pie label data={chartData} dataKey="visitors" nameKey={"browser"} />
                <ChartTooltip
                    cursor={false}
                    content={<ChartTooltipContent hideLabel />}
                />
                <ChartLegend
                    content={<ChartLegendContent nameKey="browser" />}
                    className="-translate-y-2 flex-wrap gap-2 [&>*]:basis-1/4 [&>*]:justify-center"
                />
            </PieChart_R>
        </ChartContainer>
    )
}

