import axios from "axios"; import { IESQueryResponse } from "../app.interface";
import {
  IMasterPartCreateRequest,
  IPart,
  ListProps,
  PartStatus,
  IMasterPartRequest,
} from "../modules/module.interface";
import { testImage, testImageList } from "./testcalles";
import { CreateRequestRecord } from "./request.service";
import { log } from "../util/log";
// Create the requiredAttributes array, including all keys

export const ListParts = async <T>({
  member_id,
  pageIndex,
  pageSize,
  searchQuery,
  startDate,
  endDate,
  sortField,
  sortOrder,
  filters,
}: ListProps): Promise<IESQueryResponse<IPart>> => {
  try {
    const response = await axios.post("/member-part-inventory/list", {
      member_id,
      pageIndex,
      pageSize,
      searchQuery,
      startDate,
      endDate,
      sortField,
      sortOrder,
      filters,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

// TODO: Remove docTemp after testing
export const CreatePart = async (doc: IPart) => {
  try {
    const docTemp = {
      created_at: 1718702394318,
      inv_part_id: "dbeb53bd7038666607c47328139cb917",
      model_id: 457,
      make_name: "TOYOTA test",
      manufacturer_name: "Ford Test",
      make_id: 1112,
      member_name: "Venestusy Development W.L.L",
      unit_price: 12.63,
      is_oem: false,
      model_name: "Camry I Liftback (V10) Test",
      last_update: 1718702394318,
      manufacturer_country: "USA",
      part_id: "XycJr48B7L1shVnnAHZI",
      inv_id: "fdd79d6ed6a717cd16fcd08adb411ab4",
      category: "Brakes",
      part_name: "test",
      staff_email: "m.waleed65@gmail.com",
      status: PartStatus.Available,
      images: doc.images,
      default_image: doc.default_image,
    };
    const response = await axios.post("/part/add", {
      doc: doc.part_name ? doc : docTemp,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

// TODO: backend update image not working
export const UpdatePart = async (doc: IPart) => {
  try {
    const docTemp = { ...doc };
    if ("images" in docTemp && docTemp.images && docTemp.images.length > 0) {
      var errors;
      docTemp.images = docTemp.images.map((image) => {
        if (image.startsWith("https://vsto-website-images.s3.amazonaws.com")) {
          const imageId = extractGroupOneFromUrl(image);
          if (imageId) return imageId;
          else {
            errors = true;
            return image;
          }
        } else {
          return image;
        }
      });
      if (errors)
        //TODO: better error messages
        return Promise.reject({
          errors: "Invalid images url",
        });
    }
    if (
      "default_image" in docTemp &&
      docTemp.default_image &&
      docTemp.default_image.startsWith(
        "https://vsto-website-images.s3.amazonaws.com"
      )
    ) {
      const imageId = extractGroupOneFromUrl(docTemp.default_image);
      if (imageId) docTemp.default_image = imageId;
      else
        return Promise.reject({
          errors: "Invalid default_image url",
        });
    }
    log("UpdatePart", { doc });
    const response = await axios.post("/part/update", {
      doc: docTemp,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

export const DeletePart = async (doc: IPart) => {
  try {
    const response = await axios.post("/part/delete", { _id: doc._id });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

export const GetMasterParts = async () => {
  try {
    const response = await axios.post("/part/list-master");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

export const CreateMasterPartRequest = async (
  masterPartRequestRecord: IMasterPartCreateRequest
) => {
  const requestRecord: IMasterPartRequest = {
    action: "Create",
    module: "part",
    value: masterPartRequestRecord,
    created_by: "XXXXXXXXXXXXXXXXXXXX",
  };
  log("CreateMasterPartRequest", { requestRecord });
  return await CreateRequestRecord(requestRecord);
};

function extractGroupOneFromUrl(url: string): string | null {
  // Regular expression to match the part in Group 1
  const regex =
    /https:\/\/vsto-website-images\.s3\.amazonaws\.com\/parts\/(\w+)\?/;
  const match = url.match(regex);
  // Return the matched group (Group 1) if found, otherwise return null
  return match ? `AMAZON-ID${match[1]}` : null;
}
