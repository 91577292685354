import { IESDoc, IESQueryResponse } from "../../app.interface";
import { ITableFilters } from "../../modules/module.interface";
import axios from "../axios.service";

export const FetchMemberData = async <T>(
  member_id: number
): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post("/members/get", {
      member_id,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

export const FetchAllowedMemberData = async <T>(): Promise<
  IESQueryResponse<T>
> => {
  try {
    const response = await axios.post("/members/get-allowed");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

export const InternalGetMembersData = async <T>(
  member_id: string,
  pageIndex?: number,
  pageSize?: number,
  searchQuery?: string,
  startDate?: number,
  endDate?: number,
  dateField?: string,
  sortField?: string,
  sortOrder?: "desc" | "asc",
  filters?: ITableFilters[]
): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post("/members/list", {
      member_id: member_id, // todo: not used in the backend
      pageIndex,
      pageSize,
      searchQuery,
      startDate,
      endDate,
      dateField,
      sortField,
      sortOrder,
      filters,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const InternalGetNonParentMembersData = async <T>(): Promise<
  IESQueryResponse<T>
> => {
  try {
    const response = await axios.post("/members/list-nonparent");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const InternalCreateMemberRecord = async (
  member_id: number,
  member_name: string,
  email: string,
  created_by: string,

  is_parent: boolean,
  childs_ids?: string[],

  address?: string,
  location?: {
    latitude: number;
    longitude: number;
  },
  socials?: {
    instagram: string;
    whatsapp: string;
    phone_number: string;
  }
) => {
  try {
    const response = await axios.post("/members/add", {
      member_id,
      member_name,
      created_by,
      is_parent,
      childs_ids,
      email,
      address,
      location,
      socials,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const InternalUpdateMemberRecord = async (doc: IESDoc) => {
  try {
    const response = await axios.post("/members/update", {
      doc,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const InternalDeleteMemberRecord = async (docs: IESDoc[]) => {
  try {
    const response = await axios.post("/members/delete", {
      docs,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
