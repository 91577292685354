import { Line, LineChart } from "recharts"

import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "@/components/ui/chart"
import { chartContainerClassName } from "./_.chart"
import { chartConfig, lineChartData, xyChartConfig, xyChartEntry } from "../data/data"

export type IMiniLineChart = {
    data: xyChartEntry[],
    config: ChartConfig,
}
export const MiniLineChart: React.FC<IMiniLineChart> = ({ data, config }) => {
    return (
        <div className="w-full">
            <ChartContainer config={config} className={`${chartContainerClassName} min-h-[80px] max-h-[100px]`}>
                <LineChart
                    data={data}
                >
                    <ChartTooltip
                        cursor={false}
                        content={<ChartTooltipContent hideIndicator hideLabel />}
                    />
                    <Line
                        dataKey={xyChartConfig.y}
                        type="natural"
                        stroke={xyChartConfig.yColor}
                        strokeWidth={2}
                        dot={{ fill: xyChartConfig.yColor }}
                        activeDot={{ r: 6 }}
                    />
                </LineChart>
            </ChartContainer>
        </div>
    )
}

