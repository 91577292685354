import { PieChart } from "../charts/PieChart.chart";

export type ITab = {
    value: string;
    label: string;
    content: React.ReactNode;
};

export const tabs: ITab[] = [
    {
        value: "part-category",
        label: "Part Category",
        content: <PieChart />,
    },
    {
        value: "car-type",
        label: "Car Type",
        content: <PieChart />,
    },
    {
        value: "car-model",
        label: "Car Model",
        content: <PieChart />,
    },
];

