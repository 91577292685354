import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts"
import {
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "@/components/ui/chart"
import { chartContainerClassName } from "./_.chart"
import { chartConfig, lineChartData, xyChartConfig } from "../data/data"

export type IMultipleBarChart = {
}
export const MultipleBarChart: React.FC<IMultipleBarChart> = () => {
    return (
        <ChartContainer config={chartConfig} className={`${chartContainerClassName} max-h-[400px]`}>
            <BarChart accessibilityLayer data={lineChartData}>
                <CartesianGrid vertical={false} />
                <XAxis
                    dataKey={xyChartConfig.x}
                    tickLine={false}
                    tickMargin={10}
                    axisLine={false}
                    tickFormatter={(value) => value.slice(0, 3)}
                />
                <YAxis
                    stroke="#888888"
                    fontSize={12}
                    tickLine={false}
                    axisLine={false}
                />
                <ChartTooltip
                    cursor={false}
                    content={<ChartTooltipContent indicator="dashed" />}
                />
                <Bar dataKey={xyChartConfig.y} fill={xyChartConfig.yColor} radius={4} />
                <Bar dataKey={xyChartConfig.y2} fill={xyChartConfig.y2Color} radius={4} />
            </BarChart>
        </ChartContainer>
    )
}

