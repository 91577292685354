// TODO: @ahmed less <> and animation and validation and Permisitons
import { Skeleton } from "@/components/ui/skeleton";
import __ from "@/lang/__";
import { RenderCell } from "@/util/render.cell";
import { WritableCell } from "../components/writeable.cell";
import { IWritableCell } from "../_.types";

export type FieldProps = IWritableCell & {
  loading: boolean;
  editMode: boolean;
  hideTitle?: boolean;
  customRender?: (entity: any) => React.ReactNode;
}
export function Field({
  customRender,
  loading,
  editMode = false,
  hideTitle = false,
  renderType,
  entity,
  masterColumnName,
  masterColumnTitle,
  ...props
}: FieldProps) {
  if (!entity)
    entity = {}
  return <>
    {loading ?
      <div className="w-full relative flex flex-col items-start gap-1">
        {!hideTitle && < div >
          <Skeleton className="h-4 w-[150px]" />
        </div>}
        <div>
          <Skeleton className="h-4 w-[250px]" />
        </div>
      </div>
      : <>
        {entity && !loading && editMode ?
          <WritableCell
            entity={entity}
            renderType={renderType}
            masterColumnName={masterColumnName}
            masterColumnTitle={masterColumnTitle}
            {...props}
          />
          : customRender ?
            <>{customRender(entity)}</> :
            <div className="flex flex-col items-start gap-1">
              {!hideTitle && <div className="font-semibold">
                {__(masterColumnTitle)}
              </div>}
              <div>
                {entity && entity[masterColumnName] ? RenderCell(entity[masterColumnName], renderType) : "-"}
              </div>
            </div>
        }
      </>
    }
  </>
}
