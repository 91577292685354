import React from "react"
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs"
import { _ChartCardClassName } from "./_.chartwrapper";
import { IChartCard } from "./SingleChart.chartwrapper";
import { ITab } from "../../data/chartWithSelect.data";

type IChartWithSelect = Omit<IChartCard, "children" | "description"> & {
    tabs: ITab[],
    defaultChart: string,
}
export const ChartWithSelect: React.FC<IChartWithSelect> = ({
    defaultChart,
    tabs,
    title,
    className,
}) => {
    const isDefaultFound = tabs.some(tab => tab.value === defaultChart)
    return (
        <Card className={`${_ChartCardClassName} ${className}`}>
            <Tabs
                defaultValue={isDefaultFound ? defaultChart : tabs[0].value}
                className="w-full"
            >
                <div className="pr-2 flex items-center gap-4 justify-start">
                    <CardHeader className="text-xl">
                        <CardTitle>{title}</CardTitle>
                    </CardHeader>
                    {/* Tabs */}
                    <TabsList>
                        {tabs.map(({ value, label }) => (
                            <TabsTrigger key={value} value={value} className="text-sm font-medium">
                                {label}
                            </TabsTrigger>
                        ))}
                    </TabsList>
                </div>
                <CardContent>
                    {tabs.map(({ value, content }) => (
                        <TabsContent key={value} value={value}>
                            {content}
                        </TabsContent>
                    ))}
                </CardContent>
            </Tabs>
        </Card>
    );
}

