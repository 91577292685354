import React from "react"

import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import { _ChartCardClassName } from "./_.chartwrapper"

export type IChartCard = {
    title: string,
    description: string
    children?: React.ReactNode
    className?: string,
}
export const ChartCard: React.FC<IChartCard> = ({
    title,
    description,
    children,
    className = "md:col-span-4 xl:col-span-2"
}) => {
    return <>
        <Card className={`${_ChartCardClassName} ${className}`}>
            <CardHeader>
                <CardTitle>{title}</CardTitle>
                <CardDescription>
                    {description}
                </CardDescription>
            </CardHeader>
            <CardContent>
                {children}
            </CardContent>
        </Card>
    </>
}
