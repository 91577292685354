import { Button } from "@/components/ui/button"
import { Badge } from "@/components/ui/badge"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import __ from "@/lang/__"
import { Pencil, Trash2, Plus } from "lucide-react"
import { useRTeamMember } from "../store/membership.member-request.store"
import { DialogForm } from "@/components/dialogs/dialog.form"
import { useEffect } from "react"

const connections = [
  { name: "Olivia Davis", email: "olivia.davis@example.com", status: "connect" },
  { name: "John Doe", email: "john.doe@example.com", status: "connect" },
]

export function RequestedTeamMembers() {
  const {
    rTeamMember,
    setRTeamMember,
    oldRTeamMember,
    setOldRTeamMember,
    isEditRTeamMemberOpen,
    setIsEditRTeamMemberOpen,
    rTeamMembers,
    handleChange,
    handleEditeRTeamMember,
    handleDeleteRTeamMember,
  } = useRTeamMember()
  const onEdit = (tm: typeof rTeamMember) => { setRTeamMember(tm); setOldRTeamMember(tm); setIsEditRTeamMemberOpen(true) }
  const onDelete = (id: string) => { handleDeleteRTeamMember(id) }
  useEffect(() => {
  }, [rTeamMembers])
  return (
    <Card className="border-upcoming max-h-[500px]">
      <CardHeader>
        <div className="w-full flex justify-between">
          <div className="font-semibold">{__("Requested New Memebers")}
            <Badge variant={"outline"} className="inline mx-1 border-upcoming text-upcoming bg-upcoming/20">{__("Waiting Scrap Team Approval")}</Badge>
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {
            rTeamMembers.map((tm, i) => (tm._id &&
              <div key={i} className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <span className="relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full">
                    <span className="flex h-full w-full items-center justify-center rounded-full bg-muted">
                      {tm.member_name.split(" ").map((n) => n[0]).join("")}
                    </span>
                  </span>
                  <div>
                    <div className="font-medium">{tm.member_name}</div>
                    <div className="text-xs text-muted-foreground">{tm.email}</div>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <DialogForm
                    open={oldRTeamMember == tm && isEditRTeamMemberOpen}
                    onOpenChange={(open) => setIsEditRTeamMemberOpen(open)}
                    title="Invite Team Member"
                    description="Enter the email address of the team member you would like to invite."
                    onSubmit={handleEditeRTeamMember}
                    fields={[
                      {
                        loading: false,
                        editMode: true,
                        masterColumnName: "member_name",
                        masterColumnTitle: __("Team Member Name"),
                        renderType: "text",
                        required: true,
                        entity: rTeamMember,
                        handleChange,
                      },
                      {
                        loading: false,
                        editMode: true,
                        masterColumnName: "email",
                        masterColumnTitle: __("Email"),
                        renderType: "text",
                        required: true,
                        entity: rTeamMember,
                        handleChange,
                      },

                    ]}
                  >
                    <Button size={"icon"} onClick={() => onEdit(tm)} variant={"outline"}>
                      <Pencil />
                    </Button>
                  </DialogForm>
                  <Button size={"icon"} onClick={() => onDelete(tm._id!)} variant={"destructive"}>
                    <Trash2 />
                  </Button>
                </div>
              </div>
            ))
          }
        </div>
      </CardContent>
    </Card>
  )
}

