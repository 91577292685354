import * as React from "react"
import {
  FolderCode,
  LayoutDashboard,
  Settings,
  Warehouse,
} from "lucide-react"
import { NavMain } from "@/components/nav-main"
import { NavUser } from "@/components/nav-user"
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail,
  useSidebar,
} from "@/components/ui/sidebar"
import { MySidebarHeader } from "@/components/sidebar/company-logo"
import vLogo from "@/assets/logo/venustusy-logo-full.png";
import { cn } from "@/lib/utils"

// This is sample data.
// TODO: use module.register
const data = {
  user: {
    name: "shadcn",
    email: "m@example.com",
    avatar: "/avatars/shadcn.jpg",
  },
  navMain: [
    {
      title: "Dashboard",
      url: "/dashboard",
      icon: LayoutDashboard,
    },
    {
      title: "Inventory",
      url: "/inventory/part",
      icon: Warehouse,
      isActive: true,
      items: [
        {
          title: "Part",
          url: "/inventory/part",
        },
      ],
    },
    {
      title: "Settings",
      url: "/settings/membership",
      icon: Settings,
      items: [
        {
          title: "Membership Detail",
          url: "/settings/membership",
        },
        {
          title: "Manage Users",
          url: "/settings/users",
        },
      ],
    },
    {
      title: "Internal",
      url: "/internal/requests",
      icon: FolderCode,
      items: [
        {
          title: "Manage Requests",
          url: "/internal/requests",
        },
        {
          title: "Internal Members",
          url: "/internal/members",
        },
      ],
    },
  ],
}
// TODO: add another image to prevent the transition glitch
export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const { state } = useSidebar()
  return (
    <Sidebar collapsible="icon" {...props}>
      <SidebarHeader>
        <MySidebarHeader />
      </SidebarHeader>
      <SidebarContent>
        <NavMain items={data.navMain} />
      </SidebarContent>
      <SidebarFooter>
        <div
          className="absolute bottom-0 left-0 w-full h-14 bg-gradient-to-r from-[#0C1339] to-black cursor-pointer overflow-hidden"
          onClick={() => window.open("https://www.venustusy.com/", "_self")}
        >
          <div className="relative w-full h-full p-1">
            <img
              src={vLogo}
              alt="venustusy-logo"
              className={cn("absolute w-[190px]",
                state === "collapsed" && "scale-[400%] origin-bottom-left bottom-1 left-1",
                state !== "collapsed" &&"top-2.5 left-6"
              )}
            />
          </div>
        </div>
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  )
}
