import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import _ from 'lodash';
import { IMember, IMembershipChildCreateRequest } from '@/modules/module.interface';
import { CreateMembershipChildRequestRecord, GetMemberShip } from '@/services/membership.service';
import { toast } from '@/state/snackbar';
import { useMembershipStore } from './membership.store';
import { useRTeamMember } from './membership.member-request.store';
import { requestEditMemberWrapper } from './utils/request-wrapper';
import { CreateRequestRecord } from '@/services/request.service';

export type ITeamMemberView = IMember & {
    message?: string
}
interface ITeamMembers {
    teamMembers: ITeamMemberView[] | [];
    setTeamMembers: (teamMembers: ITeamMemberView[]) => void;
    teamMember: IMember | IMembershipChildCreateRequest | undefined;
    setTeamMember: (activeTeamMemberRequest: IMember | IMembershipChildCreateRequest | undefined) => void;
    oldTeamMember: IMember | undefined;
    setOldTeamMember: (activeTeamMemberRequest: IMember | undefined) => void;
    isAddTeamMemberOpen: boolean;
    setIsAddTeamMemberOpen: (isAddTeamMemberOpen: boolean) => void;
    isEditTeamMemberOpen: boolean;
    setIsEditTeamMemberOpen: (isAddTeamMemberOpen: boolean) => void;
    handleChange: (value: any, field: string) => void
    getMemberProfile: (member_id: string) => IMember;
    handleSubmitAddMember: () => void; // Create
    handleEditTeamMember: () => void; // Update
    handleDeleteTeamMember: () => void;
}

export const useTeamMembers = create<ITeamMembers>()(
    devtools((set, get) => ({
        teamMembers: [],
        teamMember: undefined,
        oldTeamMember: undefined,
        isAddTeamMemberOpen: false,
        isEditTeamMemberOpen: false,

        setTeamMembers: (teamMembers) => set({ teamMembers }),
        setTeamMember: (teamMember) => set({ teamMember }),
        setOldTeamMember: (oldTeamMember) => set({ oldTeamMember }),
        setIsAddTeamMemberOpen: (isAddTeamMemberOpen) => set({ isAddTeamMemberOpen }),
        setIsEditTeamMemberOpen: (isEditTeamMemberOpen) => set({ isEditTeamMemberOpen }),


        handleChange: (value, field) => {
            const { teamMember, setTeamMember } = get();
            if (!teamMember) {
                setTeamMember({ member_name: "", email: "", [field]: value });
                return;
            }
            const updatedMember = { ...teamMember, [field]: value };
            setTeamMember(updatedMember);
        },

        getMemberProfile: (member_id) => {
            // TODO: Validation
            GetMemberShip(member_id).then((res) => {
                return res.data[0];
            }).catch(() => {
                toast(`Error happened while getting ${member_id} member profile`, "error");
            })
        },

        handleSubmitAddMember: () => {
            const { member } = useMembershipStore.getState()
            const { getPreviousTeamMemberRequests } = useRTeamMember.getState()
            const { teamMember, setTeamMember, setIsAddTeamMemberOpen } = get();
            // TODO: Validation
            // TODO: show Error message
            if (!teamMember || !member) return;
            const membership: IMembershipChildCreateRequest = { ...(teamMember as IMembershipChildCreateRequest), created_by: member.member_id }
            console.log(membership)
            CreateMembershipChildRequestRecord(membership)
                .then((res) => {
                    console.log(res);
                    toast("Member added successfully", "success");
                    setIsAddTeamMemberOpen(false)
                    setTeamMember(undefined)
                    getPreviousTeamMemberRequests(member.member_id)
                }).catch((error) => {
                    toast("Error happened while adding the member", "error");
                    console.log(error);
                });
        },

        handleEditTeamMember: () => {
            const { member } = useMembershipStore.getState()
            const { getPreviousTeamMemberRequests } = useRTeamMember.getState()
            const {
                teamMember,
                setTeamMember,
                oldTeamMember,
                setOldTeamMember,
                setIsEditTeamMemberOpen,
            } = get();
            // TODO: Validation Request
            if (!teamMember || !oldTeamMember) return;
            const requestRecord = requestEditMemberWrapper(teamMember, oldTeamMember);
            if (!requestRecord) return;
            if (member && member.member_id) {
                const updateRequestRecordProms = {
                    ...requestRecord,
                    created_by: member.member_id,
                };
                CreateRequestRecord(updateRequestRecordProms)
                    .then(() => {
                        toast("Your Request Created Successfully", "success")
                        setIsEditTeamMemberOpen(false)
                        setTeamMember(undefined)
                        setOldTeamMember(undefined)
                        getPreviousTeamMemberRequests(member.member_id)
                    })
                    .catch(
                        (error) => (
                            console.log(error),
                            toast("Error happened while creating the request", "error")
                        )
                    )
            }
        }
    }))
);

