import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
interface IChildsIdsMultiSelect {
  isParent: boolean;
  setChildsIds: React.Dispatch<React.SetStateAction<string[]>>;
  options: string[];
  // options: { member_name: string; member_id: number }[];
  selectedOptions?: string[];
  // selectedOptions?: { member_name: string; member_id: number }[];
}
export const ChildsIdsMultiSelect: React.FC<IChildsIdsMultiSelect> = ({
  isParent,
  setChildsIds,
  options,
  selectedOptions,
}) => {
  return (
    <Autocomplete
      multiple
      disabled={!isParent}
      id="multi-select-member"
      options={options}
      value={selectedOptions || undefined}
      disableCloseOnSelect
      // getOptionLabel={(option) => option.member_name}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {/* {option.member_name} */}
            {option}
          </li>
        );
      }}
      onChange={(e, value) => {
        // const member_ids = value.map((item) => item.member_id);
        const member_ids = value.map((item) => item);
        setChildsIds(member_ids);
      }}
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField {...params} label="Childs" placeholder="Select Childs" />
      )}
    />
  );
};
