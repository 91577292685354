import { Autocomplete, Checkbox, Grid, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import _ from "lodash";
import { WritableImageCell } from "./writable.cell.types/writable.cell.image";
import { WritableImageArrayCell } from "./writable.cell.types/writable.cell.array-images";
import { IWritableCell } from "../_.types";
import { GetCarPartImageName } from "../../part/utils/get-car-part-image-name";
import { WritableSelectableObject } from "./writable.cell.types/writable.cell.array-object";
import { log } from "../../../util/log";
import { WritableSelectableText } from "./writable.cell.types/writable.cell.array-text";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export const WritableCell: React.FC<IWritableCell> = (props) => {
  const {
    entity,
    handleChange,
    handleChangeObj,
    masterColumnName,
    masterColumnTitle,
    renderType,
    required,
    selectable,
    immutable,
  } = props;
  switch (renderType) {
    case "image":
      const initialImagePath = _.get(entity, masterColumnName);
      return (
        <WritableImageCell
          initialImagePath={
            initialImagePath
              ? initialImagePath
              : entity.part_name
                ? GetCarPartImageName(entity.part_name)
                : undefined
          }
          {...props}
        />
      );
    case "image-array":
      return <WritableImageArrayCell {...props} />;

    case "object-array":
      return <WritableSelectableObject {...props} />;

    case "text-array":
      return <WritableSelectableText {...props} />;

    default:
      return (
        <>
          <Grid item lg={6} md={6} sm={12} xs={12} key={masterColumnName}>
            <TextField
              disabled={immutable}
              label={masterColumnTitle}
              size="small"
              fullWidth
              required={required}
              type={renderType}
              onChange={(e) => handleChange(e.target.value, masterColumnName)}
              // TODO: fix any
              value={(entity as any)[masterColumnName] || ""}
            />
          </Grid>
        </>
      );
  }
};
