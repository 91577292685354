import {
  Popover,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import React, { Fragment, useEffect, useRef } from "react";
import Lottie from "react-lottie";
import "../styles.css";
import { user$ } from "@/state/user";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { useBehaviourSubject, useObservable } from "@/hooks/user-obsevrable";
import { UserPreferences } from "../user.preferences";
import { PreBuildThemes } from "../appearance/themes.prebuilt";
import animationData from "@/assets/lottie/profile.json";
import { Accessibility } from "../accessibility/accessibility";

// TODO: add user image instead of avatar
// TODO: remove MUI
export const AppUserProfile: React.FC = () => {
  const user = useBehaviourSubject(user$);
  const userSelectedTheme = useObservable(
    UserPreferences.local("selected_theme")
  );
  const [userMenuEl, setUserMenuEl] = React.useState<null | HTMLElement>(null);

  if (!user) {
    return null;
  }
  return (
    <Fragment>
      <Fragment>
        <div
          onClick={(e) => setUserMenuEl(e.currentTarget)}
        >
          <Avatar className="h-8 w-8">
            <AvatarImage src="./avatar.png" alt="User avatar" />
            <AvatarFallback>UI</AvatarFallback>
          </Avatar>
        </div>

        <Popover
          open={Boolean(userMenuEl)}
          anchorEl={userMenuEl}
          onClose={() => setUserMenuEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box className="user-popover-container">
            <Box className="user-profile-header">
              <Box className="image">
                <Lottie
                  options={{
                    animationData: animationData,
                    autoplay: true,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  width={60}
                  height={60}
                />
              </Box>
              <Box className="user-basic-info">
                <Typography
                  variant="h5"
                  className="user-name"
                  color="primary"
                >
                  {user?.staff_name}
                </Typography>
                <Typography
                  variant="h6"
                  className="user-email"
                  color="textPrimary"
                  fontWeight={600}
                >
                  {user?.staff_email}
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Box className="user-control-panel">
              <PreBuildThemes theme={userSelectedTheme} />
            </Box>
            <Box className="user-control-panel">
              <Accessibility onClose={() => setUserMenuEl(null)} />
            </Box>
          </Box>
        </Popover>
      </Fragment>
    </Fragment >
  );
};
