import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import _, { isNumber, isString } from "lodash";
import React, { Fragment, useEffect } from "react";
import { IESDoc } from "../../../../app.interface";
import { toast } from "../../../../state/snackbar";
import { UUIDGenerator } from "../../../../util/uuid.generator";
import useDeepCompareEffect from "use-deep-compare-effect";
import { ValidateEmail } from "../../../../util/validator";
import {
  DeleteStaffRecord,
  FetchUserRoles,
  GetUserPermissions,
  UpdateCurrentUser,
} from "../../../../services/settings.service";
// import { syncUserRecord } from "../../../../util/sync.user";
import { isPermissionValid } from "../../../../util/user-permission-validator";
import { SetRolePermissions } from "../../../settings/manage_users/views/set-role-permissions.view";
import {
  InternalDeleteMemberRecord,
  InternalGetNonParentMembersData,
  InternalUpdateMemberRecord,
} from "../../../../services/admin-panel/member.service";
import { IMember } from "../../../module.interface";
import { ChildsIdsMultiSelect } from "../components/childsIds-multiple-select";

interface IMemberEditView extends IMember {
  is_deleted: boolean;
}

const previewStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "53vh",
  height: "25vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  borderRadius: 2,
};
const EditMemberRecord: React.FC<{
  doc: IESDoc;
  setUpdateID: React.Dispatch<React.SetStateAction<string>>;
  onCloseModel: () => void;
  permissionsStructure: string[];
}> = ({ doc, setUpdateID, onCloseModel, permissionsStructure }) => {
  const [member, setMember] = React.useState<IMemberEditView>({
    member_id: doc.member_id,
    member_name: doc.member_name,

    is_parent: doc.is_parent,
    childs_ids: doc.childs_ids,

    email: doc.email,
    address: doc.address,

    location: doc.location,
    socials: doc.socials,

    category: doc.category,
    type: doc.type,

    is_deleted: doc.is_deleted,
  });
  const [oldMember, setOldMember] = React.useState<IMemberEditView>({
    member_id: doc.member_id,
    member_name: doc.member_name,

    is_parent: doc.is_parent,
    childs_ids: doc.childs_ids,

    email: doc.email,
    address: doc.address,

    location: doc.location,
    socials: doc.socials,

    category: doc.category,
    type: doc.type,

    is_deleted: doc.is_deleted,
  });

  const [submitCounter, setSubmitCounter] = React.useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] =
    React.useState<boolean>(false);
  const [hasChanged, setHasChanged] = React.useState<boolean>(false);
  const [childsIds, setChildsIds] = React.useState<string[]>(doc.childs_ids);
  const [childsIdsStructure, setChildsIdsStructure] = React.useState<
    { member_id: string; member_name: string }[]
  >([]);
  const fetchNoneParentMembers = () => {
    InternalGetNonParentMembersData()
      .then((nonParentMembers) => {
        setChildsIdsStructure(
          nonParentMembers.data.map((v) => {
            return { member_name: v.member_name, member_id: v.member_id };
          })
        );
      })
      .catch(() => {
        toast("Error happened while fetching nonParentMembers", "error");
      });
  };
  useEffect(fetchNoneParentMembers, []);
  // const [fetchingUserRoles, setFetchingUserRoles] =
  //   React.useState<boolean>(false);
  // const [userRolesList, setUserRolesList] = React.useState<string[]>([]);
  // const [resultedPermissions, setResultedPermissions] = React.useState<
  //   string[]
  // >(doc.permissions_list);
  // const [rolePermissions, setRolePermissions] = React.useState<string[]>(
  //   doc.permissions_list
  // );
  // const [customizingPermissionsModal, setCustomizingPermissionsModal] =
  //   React.useState<boolean>(false);
  const [deleteCounter, setDeleteCounter] = React.useState<boolean>(false);

  // const getUserRoles = (role: string) => {
  //   setFetchingUserRoles(true);
  //   GetUserPermissions(role)
  //     .then((response) => {
  //       const x: IESDoc[] = response.data;
  //       setResultedPermissions(x[0].default_permission);
  //       setRolePermissions(x[0].default_permission);
  //     })
  //     .catch(() => {
  //       toast("Error happened while getting user permissions", "error");
  //     })
  //     .finally(() => {
  //       setFetchingUserRoles(false);
  //     });
  // };
  const handleChange = (e: any, field: string) => {
    const x = { ...member };
    switch (field) {
      case "member_name":
        x.member_name = e.target.value;
        break;
      case "member_id":
        x.member_id = e.target.value;
        break;

      case "is_parent":
        x.is_parent = e.target.checked;
        break;
      case "childs_ids":
        x.childs_ids = childsIds;
        break;

      case "email":
        x.email = e.target.value;
        break;
      case "address":
        x.address = e.target.value;
        break;

      case "location":
        x.location = e.target.value;
        break;
      case "socials":
        x.socials = e.target.value;
        break;

      case "category":
        x.category = e.target.value;
        break;
      case "type":
        x.type = e.target.value;
        break;
    }
    setMember(x);
  };

  const handleSubmit = () => {
    setSubmitCounter(true);
    const updatedUserRecord: IESDoc = {
      _id: doc._id,

      member_id: member.member_id,
      member_name: member.member_name,

      is_parent: member.is_parent,
      childs_ids: member.childs_ids,

      email: member.email,
      address: member.address,

      location: member.location,
      socials: member.socials,

      category: member.category,
      type: member.type,
    };

    InternalUpdateMemberRecord(updatedUserRecord)
      .then(() => {
        toast("user updated successfully", "success");
        setOldMember({ ...member });
        setUpdateID(UUIDGenerator());

        // syncUserRecord(member);
      })
      .catch(() => {
        toast("error happened while updating the user", "error");
      })
      .finally(() => {
        setSubmitCounter(false);
      });
  };
  const deleteUser = () => {
    setDeleteCounter(true);
    InternalDeleteMemberRecord([doc])
      .then(() => {
        toast("user deleted successfully", "success");
        setIsDeleteModalOpen(false);
        setUpdateID(UUIDGenerator());
        onCloseModel();
      })
      .catch(() => {
        toast("error happened while deleting the user", "error");
      })
      .finally(() => {
        setDeleteCounter(false);
      });
  };

  // const fetchUserRoles = () => {
  //   setFetchingUserRoles(true);
  //   FetchUserRoles()
  //     .then((response) => {
  //       const rolesData: IESDoc[] = response.data;
  //       const list: string[] = [];
  //       rolesData.forEach((item) => {
  //         list.push(item.role);
  //       });
  //       setUserRolesList(list);
  //     })
  //     .catch(() => {
  //       toast("Error fetching user roles", "error");
  //     })
  //     .finally(() => {
  //       setFetchingUserRoles(false);
  //     });
  // };

  // useEffect(fetchUserRoles, []);

  //   useEffect(fetchIncidentsList, []);
  useDeepCompareEffect(() => {
    if (
      !_.isEqual(member, oldMember)
      // || !_.isEqual(_.sortBy(resultedPermissions), _.sortBy(doc.permissions_list))
    ) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
  }, [
    member,
    oldMember,
    // resultedPermissions,
    doc,
  ]);

  return (
    <Fragment>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 3, mb: 1 }}>
          <Typography
            color="secondary"
            variant="h6"
            sx={{ fontSize: 16, mb: 0.5, fontWeight: 600 }}
          >
            User Information
          </Typography>
          <Divider />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <TextField
                label="Member Id"
                size="small"
                type="number"
                fullWidth
                onChange={(e) => {
                  handleChange(e, "member_id");
                }}
                value={member.member_id}
                error={
                  !_.isEmpty(member.member_id)
                    ? !isNumber(member.member_id)
                    : undefined
                }
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <TextField
                label="Member Name"
                size="small"
                fullWidth
                onChange={(e) => {
                  handleChange(e, "member_name");
                }}
                value={member.member_name}
              />
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={member.is_parent}
                            onChange={(e) => {
                              handleChange(e, "is_parent");
                            }}
                            name="isParent"
                          />
                        }
                        label="is Parent?"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {/* // TODO: Childs Ids INPUT */}
            {/* <Grid item alignSelf="center">
              <Tooltip title="Customize The Permissions">
                <IconButton
                  size="small"
                  onClick={() => {
                    setCustomizingPermissionsModal(true);
                  }}
                >
                  <Icon color="info">settings</Icon>
                </IconButton>
              </Tooltip>
            </Grid> */}

            <ChildsIdsMultiSelect
              isParent={member.is_parent}
              setChildsIds={setChildsIds}
              options={childsIdsStructure.map((x) => x.member_id)}
              selectedOptions={childsIds}
            />
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <TextField
                label="Email"
                size="small"
                fullWidth
                onChange={(e) => {
                  handleChange(e, "email");
                }}
                value={member.email}
                error={
                  !_.isEmpty(member.email)
                    ? !ValidateEmail(member.email as string)
                    : undefined
                }
              />
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                label="Address"
                size="small"
                fullWidth
                onChange={(e) => {
                  handleChange(e, "address");
                }}
                value={member.address}
                error={
                  !_.isEmpty(member.address)
                    ? !isString(member.address as string)
                    : undefined
                }
              />
            </Grid>

            {/* // TODO: Location INPUT */}
            {/* // TODO: Social INPUT */}
          </Grid>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 5 }}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <LoadingButton
                loading={deleteCounter}
                size="small"
                color="error"
                startIcon={<Icon>delete</Icon>}
                variant="outlined"
                disabled={
                  submitCounter
                  // || !isCiruActionAllowed("user", "delete")
                }
                onClick={() => setIsDeleteModalOpen(true)}
              >
                Delete User
              </LoadingButton>
            </Grid>
            <Grid item>
              <Grid container justifyContent="flex-end" spacing={1}>
                <Grid item>
                  <Button
                    color="secondary"
                    size="small"
                    variant="outlined"
                    startIcon={<Icon>history</Icon>}
                    onClick={() => {
                      setMember({ ...oldMember });
                      // setResultedPermissions(doc.permissions_list);
                    }}
                    disabled={!hasChanged || submitCounter}
                  >
                    Revert
                  </Button>
                </Grid>
                <Grid item>
                  <LoadingButton
                    loading={submitCounter}
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={
                      !hasChanged
                      //  || !isCiruActionAllowed("user", "edit")
                    }
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isDeleteModalOpen ? (
        <Modal
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
        >
          <Fragment>
            <Box sx={previewStyle}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container justifyContent="center">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid container justifyContent="center" sx={{ mt: 1 }}>
                        <Grid item>
                          <Typography
                            variant="h5"
                            color="secondary"
                            margin={2}
                            sx={{ fontSize: "2.8vh" }}
                          >
                            Are you sure?
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid container justifyContent="center">
                        <Grid item>
                          <Typography
                            color="primary"
                            marginLeft={2}
                            sx={{ fontSize: "2vh", display: "inline" }}
                          >
                            {member.member_name}&nbsp;
                          </Typography>
                          <Typography
                            sx={{ fontSize: "2vh", display: "inline", mt: 1 }}
                          >
                            will be deleted immediatly.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{ position: "absolute", right: 15, bottom: 15 }}
                >
                  <Grid container>
                    <Grid item>
                      <LoadingButton
                        loading={deleteCounter}
                        disabled={
                          submitCounter ||
                          !isPermissionValid("inventory.delete")
                        }
                        color="secondary"
                        variant="outlined"
                        size="small"
                        onClick={deleteUser}
                      >
                        Delete
                      </LoadingButton>
                    </Grid>

                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        sx={{ ml: 2 }}
                        onClick={() => setIsDeleteModalOpen(false)}
                        disabled={deleteCounter}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Fragment>
        </Modal>
      ) : null}

      {/* {customizingPermissionsModal ? (
        <Dialog
          fullWidth
          maxWidth={"lg"}
          open={true}
          onClose={() => {
            setCustomizingPermissionsModal(false);
            setResultedPermissions(rolePermissions);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Customize User Permissions
          </DialogTitle>
          <DialogContent>
            <Divider sx={{ mt: 1, mb: 2 }} />
            <Stack spacing={2}>
              <Typography sx={{ fontSize: 16 }}>
                Select the Permissions to be Customized to the selected User.
              </Typography>
              {fetchingUserRoles ? (
                <Grid container>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Skeleton variant="rectangular" height={300} />
                  </Grid>
                </Grid>
              ) : (
                <Box>
                  <SetRolePermissions
                    roleName={""}
                    permissionsStructureList={permissionsStructure}
                    resultedPermissions={resultedPermissions}
                    setResultedPermissions={setResultedPermissions}
                    isUpdating={true}
                  />
                </Box>
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent={"flex-end"} spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    setCustomizingPermissionsModal(false);
                    setResultedPermissions(rolePermissions);
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  disabled={
                    // !isCiruActionAllowed("user", "edit") ||
                    _.isEqual(
                      _.sortBy(resultedPermissions),
                      _.sortBy(doc.permissions_list)
                    )
                  }
                  onClick={() => {
                    setCustomizingPermissionsModal(false);
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      ) : null} */}
    </Fragment>
  );
};

export default EditMemberRecord;
