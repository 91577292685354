import { CartesianGrid, Line, LineChart as LineChart_R, XAxis, YAxis } from "recharts"

import {
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "@/components/ui/chart"
import { chartContainerClassName } from "./_.chart"
import { chartConfig, lineChartData, xyChartConfig } from "../data/data"

export type ILineChart = {
    className?: string
}
export const LineChart: React.FC<ILineChart> = ({className}) => {
    return (
        <ChartContainer config={chartConfig} className={`${chartContainerClassName} ${className}`}>
            <LineChart_R
                accessibilityLayer
                data={lineChartData}
                margin={{
                    left: 12,
                    right: 12,
                }}
            >
                <CartesianGrid vertical={false} />
                <YAxis
                    stroke="#888888"
                    fontSize={12}
                    tickLine={false}
                    axisLine={false}
                />
                <XAxis
                    dataKey={xyChartConfig.x}
                    tickLine={false}
                    axisLine={false}
                    tickMargin={8}
                    tickFormatter={(value) => value.slice(0, 3)}
                />
                <ChartTooltip
                    cursor={false}
                    content={<ChartTooltipContent hideLabel />}
                />
                <Line
                    dataKey={xyChartConfig.y}
                    type="natural"
                    stroke={xyChartConfig.yColor}
                    strokeWidth={2}
                    dot={false}
                />
            </LineChart_R>
        </ChartContainer>
    )
}
