import React, { ReactNode } from "react"

import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs"
import { IChartCard } from "./SingleChart.chartwrapper"
import { _ChartCardClassName } from "./_.chartwrapper"

// TODO: change chartLabel to icon

type IDoulChartCard = IChartCard & {
    secondChart: ReactNode,
    secondChartValue: string,
    secondChartLabel: string,
    ChartValue: string,
    ChartLabel: string,
    defaultChart: string,
}
export const DoulChartCard: React.FC<IDoulChartCard> = ({
    title,
    description,
    secondChart,
    secondChartValue,
    secondChartLabel,
    ChartValue,
    ChartLabel,
    children,
    defaultChart,
    className = "col-span-4",
}) => {
    return <>
        <Card className={`${_ChartCardClassName} ${className}`}>
            <Tabs defaultValue={defaultChart} className="w-full">
                <div className="pr-2 flex items-center gap-4 justify-between">
                    <CardHeader>
                        <CardTitle>{title}</CardTitle>
                        <CardDescription>
                            {description}
                        </CardDescription>
                    </CardHeader>
                    {/*Tabs*/}
                    <TabsList>
                        <TabsTrigger
                            value={secondChartValue}
                            className="text-sm font-medium"
                        >
                            {secondChartLabel}
                        </TabsTrigger>
                        <TabsTrigger
                            value={ChartValue}
                            className="text-sm font-medium"
                        >
                            {ChartLabel}
                        </TabsTrigger>
                    </TabsList>
                </div>
                <CardContent>
                    <TabsContent
                        value={secondChartValue}
                    >
                        {secondChart}
                    </TabsContent>
                    <TabsContent
                        value={ChartValue}
                    >
                        {children}
                    </TabsContent>
                </CardContent>
            </Tabs>
        </Card>
    </>
}

