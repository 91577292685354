import React from "react"

import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import { IChartCard } from "./SingleChart.chartwrapper"
import { _NumberCardClassName } from "./_.chartwrapper"

export type INumberCard = IChartCard & {
    withBG?: boolean,
    bgPath?: string,
    imageClassName?: string
}
export const NumberCard: React.FC<INumberCard> = ({
    title,
    description,
    children,
    withBG = false,
    bgPath,
    imageClassName,
    className = "md:col-span-4 xl:col-span-3",
}) => {
    return <>
        <Card className={`flex flex-col ${_NumberCardClassName} ${className}`}>
            {withBG && bgPath && <div
                className={`absolute inset-0 bg-cover bg-center z-0 ${imageClassName}`}
                style={{
                    backgroundImage: `url('${bgPath}?height=400&width=300')`,
                }}
            />}
            <CardHeader className="pb-3 z-10">
                <CardTitle className="text-2xl font-bold">
                    {title}
                </CardTitle>
                <CardDescription className="text-xs text-muted-foreground">
                    {description}
                </CardDescription>
            </CardHeader>
            <CardContent className="flex flex-grow z-10">
                {children}
            </CardContent>
        </Card>
    </>
}
