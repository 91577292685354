interface SalesOverviewProps {
    orderPercentage: number
    visitPercentage: number
}

export function ProgressChart({
    orderPercentage,
    visitPercentage
}: SalesOverviewProps) {
    return <>
        <div className="w-full">
            <div className="grid grid-cols-2 gap-4 divide-x">
                <div className="flex items-center gap-3 text-sm">
                    <div className="w-12 rounded-lg bg-muted px-2 py-1 text-center">
                        {orderPercentage.toFixed(1)}%
                    </div>
                    <span>Orders</span>
                </div>
                <div className="flex items-center justify-end gap-3 text-sm">
                    <div className="w-12 rounded-lg bg-muted px-2 py-1 text-center">
                        {visitPercentage.toFixed(1)}%
                    </div>
                    <span>Visits</span>
                </div>
            </div>
            <div className="mt-4 flex overflow-hidden rounded-lg">
                <span
                    className="h-4 bg-primary"
                    style={{ width: `${orderPercentage}%` }}
                />
                <span
                    className="h-4 bg-indigo-500"
                    style={{ width: `${visitPercentage}%` }}
                />
            </div>
        </div>
    </>
}
