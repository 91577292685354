import React  from "react"
import { Bar, BarChart as BarChart_recharts, ResponsiveContainer, XAxis, YAxis } from "recharts"

import { IListEntry } from "../data/data"
import {
    Avatar,
    AvatarFallback,
    AvatarImage,
} from "@/components/ui/avatar"

export const ListBarChart: React.FC = () => {
    return <>
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-8">

        </div>
    </>
}

type IList = {
    data: IListEntry[]
}
export const List: React.FC<IList> = ({ data }) => {
    return <>
        <div className="space-y-8">
            {data.map((sale: IListEntry) => (
                <div key={sale.label} className="flex items-center">
                    <Avatar className="h-9 w-9">
                        <AvatarImage src={sale.image} alt="Avatar" />
                        <AvatarFallback>{sale.imageFallback}</AvatarFallback>
                    </Avatar>
                    <div className="ml-4 space-y-1">
                        <p className="text-sm font-medium leading-none">{sale.label}</p>
                        <p className="text-sm text-muted-foreground">{sale.subtitle}</p>
                    </div>
                    <div className="ml-auto font-medium">
                        {sale.valueHead + " " + sale.value + " " + sale.valueTail}
                    </div>
                </div>
            ))}
        </div>
    </>
}

type IBarChart = {
    data: IListEntry[],
    valueHead?: string,
    valueTail?: string
}
export const BarChart: React.FC<IBarChart> = ({ data, valueHead, valueTail }) => {
    return <>
        <ResponsiveContainer width="100%" height={350}>
            <BarChart_recharts data={data}>
                <XAxis
                    dataKey="label"
                    stroke="#888888"
                    fontSize={12}
                    tickLine={false}
                    axisLine={false}
                />
                <YAxis
                    stroke="#888888"
                    fontSize={12}
                    tickLine={false}
                    axisLine={false}
                    tickFormatter={(value) => {
                        return `${valueHead ? valueHead : ''}${value}${valueTail ? valueTail : ''}`;
                    }}
                />
                <Bar
                    dataKey="value"
                    fill="currentColor"
                    radius={[4, 4, 0, 0]}
                    className="fill-primary"
                />
            </BarChart_recharts>
        </ResponsiveContainer>
    </>
}

