import { Area, AreaChart as AreaChart_R, CartesianGrid, XAxis } from "recharts"
import {
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "@/components/ui/chart"
import { chartConfig, lineChartData } from "../data/data"
import { chartContainerClassName, xyChartConfig } from "./_.chart"

export type IMiniAreaCahrt = {}
export const MiniAreaChart: React.FC<IMiniAreaCahrt> = () => {
    return (
        <div className="w-full">
            <ChartContainer config={chartConfig} className={`${chartContainerClassName} scale-x-[103%] min-h-[80px] max-h-[100px]`}>
                <AreaChart_R
                    width={730} height={250}
                    accessibilityLayer
                    data={lineChartData}
                >
                    <ChartTooltip
                        cursor={false}
                        content={<ChartTooltipContent hideIndicator hideLabel />}
                    />
                    <defs>
                        <linearGradient id="filly" x1="0" y1="0" x2="0" y2="1">
                            <stop
                                offset="5%"
                                stopColor={xyChartConfig.yColor}
                                stopOpacity={0.8}
                            />
                            <stop
                                offset="95%"
                                stopColor={xyChartConfig.yColor}
                                stopOpacity={0.1}
                            />
                        </linearGradient>
                    </defs>
                    <Area
                        dataKey={xyChartConfig.y}
                        type="natural"
                        //fill={xyChartConfig.yColor}
                        fill="url(#filly)"
                        fillOpacity={0.4}
                        stroke={xyChartConfig.yColor}
                    />
                </AreaChart_R>
            </ChartContainer>
        </div>
    )
}

