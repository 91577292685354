import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts"

import {
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from "@/components/ui/chart"
import { chartConfig, lineChartData, xyChartConfig } from "../data/data"

export type IMultiLineChart = {}
export const MultiLineChart: React.FC<IMultiLineChart> = () => {
    return (
        <ChartContainer config={chartConfig} className="w-full max-h-[380px]">
            <LineChart
                accessibilityLayer
                data={lineChartData}
                margin={{
                    left: 12,
                    right: 12,
                }}
            >
                <CartesianGrid vertical={false} />
                <YAxis
                    stroke="#888888"
                    fontSize={12}
                    tickLine={false}
                    axisLine={false}
                />
                <XAxis
                    dataKey={xyChartConfig.x}
                    tickLine={false}
                    axisLine={false}
                    tickMargin={8}
                    tickFormatter={(value) => value.slice(0, 3)}
                />
                <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
                <Line
                    dataKey={xyChartConfig.y}
                    type="monotone"
                    stroke={xyChartConfig.yColor}
                    strokeWidth={2}
                    dot={false}
                />
                <Line
                    dataKey={xyChartConfig.y2}
                    type="monotone"
                    stroke={xyChartConfig.y2Color}
                    strokeWidth={2}
                    dot={false}
                />
            </LineChart>
        </ChartContainer>
    )
}

