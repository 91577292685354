import React from 'react'
import { Progress } from "@/components/ui/progress"
import { Star } from 'lucide-react'

const StarRating: React.FC<{ rating: number }> = ({ rating }) => {
    return (
        <div className="flex items-center gap-2 *:size-5">
            {[1, 2, 3, 4, 5].map((star) => (
                <Star
                    key={star}
                    className={star <= rating ? "fill-orange-400 stroke-orange-400" : "fill-none stroke-orange-400"}
                />
            ))}
        </div>
    )
}

export function CustomerReviewCard() {
    const ratings = [
        { stars: 5, percentage: 70, color: 'bg-[hsl(var(--chart-5))]' },
        { stars: 4, percentage: 67, color: 'bg-[hsl(var(--chart-4))]' },
        { stars: 3, percentage: 50, color: 'bg-[hsl(var(--chart-3))]' },
        { stars: 2, percentage: 42, color: 'bg-[hsl(var(--chart-2))]' },
        { stars: 1, percentage: 20, color: 'bg-[hsl(var(--chart-1))]' },
    ]

    return <>
        <div className="flex justify-between rounded-lg bg-muted p-4">
            <StarRating rating={4} />
            <span>4.5 out of 5</span>
        </div>
        <div className="mt-2 text-center text-muted-foreground">
            Total 52 reviews
        </div>
        <div className="flex flex-col space-y-6">
            {ratings.map(({ stars, percentage, color }) => (
                <div key={stars} className="flex items-center gap-4">
                    <span className="flex-shrink-0">{stars} star</span>
                    <Progress value={percentage} IndicatorClassName={color} className={`flex-grow h-2`} />
                    <span>{percentage}%</span>
                </div>
            ))}
        </div>
    </>
}


