import { ChartConfig } from "@/components/ui/chart"

const chartContainerClassName = "w-full max-h-[480px] min-h-[400px]"
const xyChartConfig = {
    x: "x",
    y: "y",
    y2: "y2",
    yColor: "var(--color-y)",
    y2Color: "var(--color-y2)",
}
const miniLineConfig = (label = "", color = "hsl(var(--chart-1))") => ({
    y: {
        label,
        color,
    },
}) satisfies ChartConfig
const LineConfig = {
    y: {
        label: "Desktop",
        color: "hsl(var(--chart-1))",
    },
} satisfies ChartConfig
const multiLineConfig = {
    y: {
        label: "Desktop",
        color: "hsl(var(--chart-1))",
    },
} satisfies ChartConfig

export {
    chartContainerClassName,
    xyChartConfig,
    miniLineConfig,
}
